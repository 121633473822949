h1, h2, .h2 {
  font-size: 34px;
  font-weight: 500;
  line-height: 34px;
  font-family: $titleFont;

  @include break-max($tab){
    font-size: 28px;
  }
}

h3 {
  font-size: $h3;
  line-height: 1.3;
  font-family: $titleFont;
  font-weight: $normal;
}

.title{
  font-family: $titleFont;
  font-weight: $bold;
  color: $color-dark;

  &--h1,
  &--h2{
    line-height: 1.36;
    font-family: $titleFont;
    font-size: $h2;

    @include break-max($tab){
      font-size: 30px;
    }

    @include break-max($mob){
      font-size: 22px;
    }
  }

  &--h3{
    font-size: $h3;
    font-weight: $normal;

    @include break-max($mob){
      font-size: 18px;
    }
  }

  &--h4{
    font-size: 16px;
    font-weight: $normal;
  }
  &--h5{
    font-size: 12px;
  }
}

.p{
  color: $color-dark;
  font-family: $mainFont;
  line-height: 1.56;

  .icon{
    padding-right: 4px;
  }

  &--primary{
    color: $color-dark;
  }

  &--subtitle{
    color: $color-grey;
    margin-bottom: 5px;
  }

  &--secondary{
    color: $color-neutral;
  }

  &--accent{
    color: $color-blue;
  }

  &--danger{
    padding-top: 5px;
    color: $color-red;
    font-style: italic;
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  &--inherit{
    color: inherit;
  }

  &--default_x{
    font-size: $main-text;
  }

  &--default_xl{
    font-size: $main-fs;
  }

  &--small{
    font-size: 12px;
  }
  
  &--hint{
    border-left: 2px solid $color-blue;
    padding: 3px 0 3px 6px;
    font-style: italic;
    line-height: 1.24;
  }
  &.ellipsis{
    white-space: nowrap;
  }
}

// Weights
.light {
  font-weight: $light;
}

.normal {
  font-weight: $normal;
}

.medium {
  font-weight: $semibold;
}
.bold {
  font-weight: $bold;
}
.extra-bold {
  font-weight: $extra-bold;
}

// Alignments
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
}

.form-error{
  color: $color-red;
}

code {
  color: $color-dark;
  white-space: unset;
  font-style: italic;
  background-color: rgba($secondary-brand-color, 0.4);
  border-radius: 2px;
  padding: 4px 12px;
  font-family: $font-family-monospace;

  a > & {
    padding: 0;
    color: inherit;
    background-color: inherit;
  }
}
.pre-scrollable {
  max-height: 320px;
  overflow-y: scroll;
}
pre {
  display: block;
  border-radius: $br;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  color: $color-dark;
  padding: 14px;
  background-color: rgba($secondary-brand-color, 0.2);
  border: 1px solid $border-color;

  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: rgba($secondary-brand-color, 0.2);
    border-radius: $br;
  }
}

.mb-4{
  margin-bottom: 20px !important;
}
.mb-3{
  margin-bottom: 15px !important;
}
.mb-2{
  margin-bottom: 10px !important;
}
.mb-1{
  margin-bottom: 5px !important;
}

.mt-4{
  margin-top: 20px !important;
}
.mt-3{
  margin-top: 15px !important;
}
.mt-2{
  margin-top: 10px !important;
}
.mt-1{
  margin-top: 5px !important;
}

.ml-4{
  margin-left: 20px !important;
}
.ml-3{
  margin-left: 15px !important;
}
.ml-2{
  margin-left: 10px !important;
}
.ml-1{
  margin-left: 5px !important;
}
.ml-0{
  margin-left: 0 !important;
}
.ml-auto{
  margin-left: auto !important;
}

.mr-4{
  margin-right: 20px !important;
}
.mr-3{
  margin-right: 15px !important;
}
.mr-2{
  margin-right: 10px !important;
}
.mr-1{
  margin-right: 5px !important;
}

.list-style-type-circle {
  list-style-type: circle;
}

.pt-5{
  padding-top: 25px !important;
}
.pt-4{
  padding-top: 20px !important;
}
.pt-3{
  padding-top: 15px !important;
}
.pt-2{
  padding-top: 10px !important;
}
.pt-1{
  padding-top: 5px !important;
}
.pb-5{
  padding-bottom: 25px !important;
}
.pb-4{
  padding-bottom: 20px !important;
}
.pb-3{
  padding-bottom: 15px !important;
}
.pb-2{
  padding-bottom: 10px !important;
}
.pb-1{
  padding-bottom: 5px !important;
}
.pb-0{
  padding-bottom: 0 !important;
}
.pl-5{
  padding-left: 25px !important;
}
.pl-4{
  padding-left: 20px !important;
}
.pl-3{
  padding-left: 15px !important;
}
.pl-2{
  padding-left: 10px !important;
}
.pl-1{
  padding-left: 5px !important;
}
.pl-0{
  padding-left: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.italic{
  font-style: italic;
}

.text-darker {
  color: $color-dark;
}

.text-secondary {
  color: $color-grey;
}

.text-danger {
  color: $color-red;
}

.text-success {
  color: $color-green;
}

.text-icon {
  color: $color-icons;
}

.order-1 {
  order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}
.order-5 {
    order: 5;
}

.--fz-main{font-size: $main-text;}

.line-clamp{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.--nowrap{
  white-space: nowrap;
}

.dark-mode{
  .card-block__title,
  .title{
    color: $color-neutral--dark;
  }

  .p{
    color: $color-second-text-color--dark;

    &--primary{
      color: $color-neutral--dark;
    }

    &--subtitle{
      color: $color-second-text-color--dark;
      margin-bottom: 5px;
      line-height: 1.3;
    }

    &--secondary{
      color: $color-second-text-color--dark;
    }

    &--accent{
      color: $color-primary--dark;
    }

    &--danger{
      color: $color-danger--dark;
      text-shadow: 0px 0px 4px rgba(#000, 0.4);
    }

    &--hint{
      border-color: $color-blue;
    }

    &--inherit{
      color: inherit;
    }
  }

  .form-error{
    color: $color-danger--dark;
    text-shadow: 0px 0px 4px rgba(#000, 0.4);
  }

  code {
    color: $color-second-text-color--dark;
    background-color: rgba($second-brand-color--dark, 0.5);
  }

  pre {
    color: $color-neutral--dark;
    background-color: rgba($second-brand-color--dark, 0.5);
    border-color: $color-border--dark;

    code {
      background-color: rgba($second-brand-color--dark, 0.5);
    }
  }

  .text-darker {
    color: $color-neutral--dark;
  }

  .text-secondary {
    color: $color-second-text-color--dark;
  }

  .text-danger {
    color: $color-danger--dark;
  }

  .text-success {
    color: darken($color-green, 18%);
  }
}