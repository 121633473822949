.dark-mode {
  .support-container {
    &-div {
      .supportChat {
        background-color: $color-warning--dark;
        color: $color-neutral-dark;

        &:before {
          border-color: $color-border--dark;
        }
      }
    }

    &--action-content {
      background: $second-brand-color--dark;
      box-shadow: 0px 0px 10px -2px rgba(#000, 0.8);
    }
    &--image {
      &:before {
        border-color: $color-border--dark;
      }
    }
    &--txt {
      color: $color-second-text-color--dark;
    }

    &--close {
      color: $color-danger--dark;
      background: $second-brand-color--dark;
    }
  }
}

// .support-container {
//   &-div {
//     display: block;

//     .supportChat {
//       position: relative;
//       background-color: $color-orange;
//       width: 55px;
//       height: 55px;
//       border-radius: 50%;
//       padding: 5px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       line-height: 1;
//       color: $color-neutral-dark;
//       z-index: 2;
//       font-size: 24px;
//       cursor: pointer;

//       svg.icon {
//         height: 24px;
//       }

//       &:before {
//         display: block;
//         content: "";
//         height: 14px;
//         width: 14px;
//         position: absolute;
//         background: $color-success;
//         border-radius: 50%;
//         bottom: 2px;
//         left: 0;
//         border: 2px solid $white;
//         transition: all 0.3s ease;
//       }

//       &:hover {
//         //background-color: rgba($color-primary, 0.8);
//       }
//     }

//     @include break-max($mob) {
//       display: none;
//     }
//   }

//   &--action-button {
//     position: fixed;
//     bottom: 5%;
//     right: 30px;
//     z-index: 1005;
//   }
//   &--action-content {
//     position: absolute;
//     top: 0;
//     right: 25px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     width: 0;
//     max-width: 0px;
//     gap: 18px;
//     border-radius: 12px 5px 5px 12px;
//     background: #fff;
//     box-shadow: 0px 0px 10px -2px rgba(#000, 0.28);
//     height: 55px;
//     padding: 0;
//     transition: all 0.3s ease;
//     opacity: 0.1;
//     z-index: 1;
//     overflow: hidden;
//   }
//   &--data {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     gap: 12px;
//     text-align: left;
//     transition: all 0.3s ease;

//     .icon {
//       color: $color-primary;
//       font-size: 20px;
//       line-height: 0.8;
//     }
//   }
//   &--image {
//     position: relative;
//     width: 40px;
//     height: 40px;
//     min-width: 40px;
//     border-radius: 6px;
//     background: url(../../../public/img/Suport_avatar_80x80.png) no-repeat center;
//     background-size: cover;
//     transform: scale(0.4);
//     opacity: 0.5;
//     transition: all 0.45s ease;

//     &:before {
//       display: block;
//       content: "";
//       height: 14px;
//       width: 14px;
//       position: absolute;
//       background: $color-success;
//       border-radius: 50%;
//       bottom: -4px;
//       right: -12px;
//       border: 2px solid $white;
//       transition: all 0.45s ease;
//       opacity: 0;
//     }
//   }
//   &--txt {
//     position: relative;
//     color: $color-neutral;
//     line-height: 1.2;
//     font-size: 12px;
//     font-weight: normal;
//     min-width: 100px;
//     white-space: nowrap;
//     transform: translateX(18px);
//     opacity: 0.4;
//     transition: all 0.4s ease;
//   }

//   &--action-button {
//     &:hover,
//     &.open {
//       .supportChat {
//         &:before {
//           transform: translateX(-12px);
//           opacity: 0;
//         }
//       }
//       .support-container {
//         &--action-content {
//           opacity: 1;
//           width: 340px;
//           max-width: 340px;
//           padding: 5px 45px 5px 10px;
//         }
//         &--image {
//           opacity: 1;
//           transform: scale(1);
//           &:before {
//             opacity: 1;
//             right: -4px;
//           }
//         }
//         &--txt {
//           opacity: 1;
//           transform: translateX(0);
//         }
//       }
//     }
//     &.open {
//       bottom: 15%;

//       .support-container--close {
//         display: flex !important;
//       }
//     }
//   }

//   &--close {
//     position: absolute;
//     top: 3px;
//     left: 4px;
//     display: none !important;
//     align-items: center;
//     justify-content: center;
//     width: 18px;
//     height: 18px;
//     border-radius: 5px;
//     font-size: 15px;
//     box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.28);
//     font-weight: 300;
//     color: $color-danger;
//     background: $white;
//     cursor: pointer;
//     z-index: 1;
//   }
// }

#supportChat {
	position: fixed;
	bottom: 5%;
	right: 30px;
}

.supportChat { 
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
  border-radius: 30px;
  background: $color-white;
  z-index: 1005;
  box-shadow: 2px 2px 15px 0 rgba(45, 51, 72, 0.10);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
	background-color: $sm-second-brand-color;
  }

  &:before {
	display: block;
	content: "";
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: $color-success;
	border: 2px solid $color-white;
	position: absolute;
	bottom: 0;
	left: 0;
  }

  &__image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: url(../../../public/img/Suport_avatar_80x80.png) no-repeat center;
    background-size: cover;
    border: 2px solid $color-orange;
  }

  &__text {
    font-size: 14px;
    color: $color-neutral-dark;
  }
}
