@import "vars";
@import "ui-kit--buttons";
@import "ui-kit--typography";
@import "ui-kit--forms";

*{
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus,
  &:active {
    outline: none !important;
  }
}

html, body{
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

body{
  font-family: $mainFont;
  font-size: $main-text;
  line-height: 1.5;
  color: $color-dark;
  overflow-x: hidden;
  background: $white;
  display: flex;
  flex-direction: column;

  > .main{
    width: 100%;
  }

  > .footer{
    margin-top: auto;
    margin-bottom: 0;
  }

  &.dark-mode{
    background: $body-bg--dark;
    color: $color-second-text-color--dark;


  }
}

.page-content{
  &__header{
    margin-bottom: 25px;

    .title{
      @include break-max($tab){
        width: 100%;
        text-align: center;
      }
    }
  }

  &__header-has-controls{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 12px;

    &:after{
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      background: $border-color;
      @include break-min($mob){
        background: $color-orange;
      }
    }

    .page-content__header-item{
      margin: 4px 10px;

      &.title{
        @include break-max($tab){
          width: 100%;
          text-align: center;
        }
      }

      &.control-bar{
        @include break-max($tab){
          width: 100%;
          order: -3;
          margin-bottom: 20px;
          margin-left: 10px;
        }
      }
    }

    .control-bar{
      margin-left: auto;
      @include break-min($tab){
        padding-left: 10px;
      }

      .btn-bar{
        display: flex;
        margin-left: -6px;
        margin-right: -6px;
        @include break-max($tab){
          justify-content: flex-end;
        }

        .btn{
          //margin: 2px 6px;

          &--back{
            &:before{
              content: '\f30a';
              display: none;
              font-size: 18px;
              margin: 0;
              @include break-max($tab){
                display: inline-block;
              }
            }
            @include break-max($tab){
              margin-right: auto;
              padding: 0 5px;
              justify-content: center;
              text-align: center;
              background: transparent;
              width: 62px;
              order: -6;
              border-radius: 25px;

              .txt{
                display: none;
              }
            }
          }

        }
        .actions__item{
          width: auto;
          min-width: 30px;
          margin: 2px 4px;
          color: $color-icons;

          .btn{
            margin: 0;
          }

          &:hover{
            color: $color-dark;
          }
        }
      }
    }
  }
  &__header-one-row{
    flex-wrap: nowrap;

    .page-content__header-item{
      &.title{
        text-align: left;
      }
      &.control-bar{
        order: unset;
        width: auto;
        margin-bottom: 4px;
      }
    }
  }
}

#HW_badge_cont {
  content: '\f0f3';
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 1.65rem;
  line-height: 100%;
  padding: 0.5rem 0.15rem;
  min-width: 50px;
  text-align: center;
  height: auto !important;
}

#HW_badge_cont:hover {
  color: $color-blue;
}

#HW_badge_cont::after {
  content: '\f0f3';
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: $light;
}

#HW_badge.HW_softHidden {
  display: none;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.zmdi-help-outline{
  color: $color-icons;

  &:before{
    font-family: "Font Awesome 6 Pro";
    content: '\f30f' !important;
    font-weight: bold;
  }
}

.table__row-item{
  .actions{
    @include break-max($mob){
      position: static;
    }
  }
}

.d-none,
.hidden{
  display: none !important;
}
.d-flex {
  display: flex !important;
}

.show-loading-animation.rect-shape,
.show-loading-animation.round-shape,
.show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row{
  background: rgba($secondary-brand-color, 0.9) !important;
}


.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 10;
}

.intl-tel-input .country-list{
  li{
    .country-name{
      color: $color-grey;
    }
    .dial-code{
      color: darken($color-grey, 4%);
    }
  }
}

body {
  .CalendarDay__selected_span {
    background: $accent-bg;
    border: 1px double darken($accent-bg, 6%);
    color: $color-dark;
  }

  .CalendarDay__selected_span:hover {
    background: darken($accent-bg, 8%);
    border: 1px double darken($accent-bg, 8%);
    color: $color-dark;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $color-orange;
    border: 1px double $color-orange;
    color: $color-dark;
  }

  .react-select__input {
    font-size: 17px !important;
  }
}

b {
  font-weight: bold;
}

.event-color {
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  padding: 0;
  display: inline-block;
  border: none !important;
  box-shadow: none;
  line-height: 1;
  border-radius: 50%;
  font-size: 0;
}

.embed-code{
  &--preview{
    position: relative;
    text-align: left;
    padding: 26px 16px 26px 16px;
    border-radius: $br;
    font-style: normal;
    background: rgba($secondary-brand-color, 0.5);
    background-image:
      repeating-linear-gradient(0deg, $color-blue, $color-blue 11px, transparent 11px, transparent 20px, $color-blue 20px),
      repeating-linear-gradient(90deg, $color-blue, $color-blue 11px, transparent 11px, transparent 20px, $color-blue 20px),
      repeating-linear-gradient(180deg, $color-blue, $color-blue 11px, transparent 11px, transparent 20px, $color-blue 20px),
      repeating-linear-gradient(270deg, $color-blue, $color-blue 11px, transparent 11px, transparent 20px, $color-blue 20px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    //background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: red; stroke-width: 1; stroke-dasharray: 20 20'/></svg>");

    .btn--secondary,
    .copy-action{
      position: absolute;
      top: 6px;
      right: 6px;
    }

    code{
      font-style: normal;
      display: block;
      width: 100%;
      background: transparent;
      color: $color-dark;
      word-break: break-word;
    }
  }
}

.location-hint {
  display: flex;
  align-items: baseline;
  gap: 0 25px;
  margin-bottom: 6px;

  &--part-hint{
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .p--hint{
      display: flex;
      align-items: flex-start;
      gap: 6px;
      font-size: 13px;

      svg{
        height: 14px;
        path{
          fill: $color-icons;
        }
      }
    }
  }

  .detect-my-location{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 5px;
    background: $sm-second-brand-color;
    cursor: pointer;
    font-size: 14px;
    gap: 6px;

    svg{
      height: 16px;
      path{
        fill: $color-neutral-dark;
      }
    }

    &:hover{
      background: darken($sm-second-brand-color, 8%);
    }
  }
}

#map {
  position: relative;
  min-height: 360px;
  z-index: 10;

  &:before, :after{
    content: '';
    display: none;
  }

  &.--updating{
    overflow: hidden;

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: $color-blue;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: 500;
      content: "\f3f4";
      animation: fa-spin 2s infinite linear;
      z-index: 100;
    }

    &:after{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#fff, 0.4);
      content: "";
      z-index: 99;
    }
  }
}


@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// hide hint tooltips for App
body{
  .btn-bar.--hide-on-app,
  .zmdi[data-toggle="tooltip"],
  .zmdi.zmdi-info-outline,
  .ico--hint.zmdi-help-outline{
    visibility: hidden;
    display: none;
  }
  &:not(.--app-version){
    .btn-bar.--hide-on-app,
    .zmdi[data-toggle="tooltip"],
    .zmdi.zmdi-info-outline,
    .ico--hint.zmdi-help-outline{
      visibility: visible;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 24px;
      min-width: 24px;
      cursor: pointer;
    }
  }

  &.--app-version{
    header.header{display: none;}
  }
}

.mt-n{ margin-top: unset; }

.icon-dragg,
.icon-drag{
  cursor: move;
  color: $color-neutral;
  svg{
    height: 24px;
  }
}
.mb-xxxl{ margin-bottom: $mb-xxxl; }
.mb-xxl{ margin-bottom: $mb-xxl; }
.mb-xl{ margin-bottom: $mb-xl; }
.mb-l{ margin-bottom: $mb-l; }
.mb-m{ margin-bottom: $mb-m; }
.mb-0,
.mb{ margin-bottom: $mb; }

.mt-xl{ margin-top: $mb-xl;}
.mt-l{ margin-bottom: $mb-l; }

.-mt-l{ margin-top: -$mb-l; }

[hidden] {
  display: none!important;
}

.--druggable{
  transition: all 0.1s ease-out;
  outline: 4px solid transparent;

  &:not(.--druggable_simple){
    cursor: pointer;
  }

  &.--druggable_simple{
    filter: none !important;
    box-shadow: none;
    outline: unset !important;

    &:active,
    &:focus,
    &:hover{
      box-shadow: unset !important;
      filter: none !important;
      outline: unset !important;
    }
  }

  .drag-handle{
    opacity: 0;
    transform: scale(0.65);
    transition: all 0.36s ease-out;
    cursor: move;
  }

  &:active,
  &:focus,
  &:hover{
    background: $secondary-brand-color;
    outline: 4px solid $secondary-brand-color;
    //filter: drop-shadow(1px 1px 2px rgba(45, 51, 72, 0.06));
    box-shadow: 1px 1px 14px rgba(45, 51, 72, 0.14);

    .drag-handle{
      opacity: 1;
      transform: scale(1);
    }
  }
}

body > .--druggable{
  pointer-events: unset;
  user-select: unset;
  cursor: move !important;
  background: $secondary-brand-color;
  outline: 4px solid $secondary-brand-color;
  //filter: drop-shadow(1px 1px 14px rgba(45, 51, 72, 0.12));
  box-shadow: 1px 1px 14px rgba(45, 51, 72, 0.14);
  //border: 1px dashed $color-blue;

  .drag-handle{
    opacity: 1;
    transform: scale(1);
    cursor: move !important;
  }
}

body.dragging{
  .--druggable{
    pointer-events: none;
    user-select: none;
  }
}

.form-group--location-type{
  .custom-switch__item.ps_custom{
    .custom-switch__icon{display: none; visibility: hidden;}
  }
}


// DARK MODE ----
body{
  &.dark-mode{
    .page-content{
      &__header-has-controls{
        &:after{
          background: $color-border--dark;
          @include break-min($mob){
            background: $color-orange;
          }
        }
      }
    }

    .show-loading-animation.rect-shape,
    .show-loading-animation.round-shape,
    .show-loading-animation.text-row,
    .show-loading-animation .rect-shape,
    .show-loading-animation .round-shape,
    .show-loading-animation .text-row{
      background: $sidebar-bg--dark !important;
    }

    .embed-code{
      &--preview{
        background: rgba(#fff, 0.1);
        color: $color-second-text-color--dark;

        code{
          color: $color-second-text-color--dark;
        }
      }
    }

    .--druggable{
      &:hover{
        background: $second-brand-color--dark;
        outline: 4px solid $second-brand-color--dark;
      }
    }

    > .--druggable{
      background: $second-brand-color--dark;
      outline: 4px solid $second-brand-color--dark;
      @include shadowDarkMode;
    }
  }
}

.fa-long-arrow-alt-left:before {content: '\f177';}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 8px;

  &:last-child{margin-right: 0;}
}

