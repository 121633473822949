a{
  text-decoration: none;
  cursor: pointer;
  color: $color-blue;
}

.ajs-button,
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 36px;
  border-radius: 25px;
  border: 1px solid;
  box-sizing: border-box;
  gap: 4px 8px;
  outline: 0;
  padding: 2px 25px;
  line-height: 1;
  font-size: 14px;
  letter-spacing: 0.01em;
  font-family: $mainFont;
  font-weight: 400;
  font-style: normal;
  touch-action: manipulation;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.36s ease;
  -webkit-tap-highlight-color: transparent;

  @include break-max($mob){
    min-height: 36px;
    font-size: 16px;
  }

  &.py-0{
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
  }

  &.collapse__toggler-track-state.btn--no-text[aria-expanded="true"],
  &.collapse__toggler-track-state[aria-expanded="true"] {
    border: 2px solid $color-marketing;
    background: $accent-bg;
    color: $color-neutral-dark;

    &:hover, &:focus, &:active {
      background: $accent-bg;
      border: 2px solid $color-marketing;
      color: $color-neutral-dark;
    }
  }

  &:disabled,
  &--disabled{
    background: rgba($disableBtnBg, 0.75); //#788291;
    border-color: rgba($disableBtnBg, 0.8); //#788291;
    color: $disableBtnColor;
    cursor: not-allowed;
    //mix-blend-mode: multiply;

    .icon, .ico{
      color: $color-icons;
    }

    &:hover, &:focus{
      background: rgba($disableBtnBg, 0.75); //#788291;
      border-color: rgba($disableBtnBg, 0.8); //#788291;
      color: $disableBtnColor;
    }
  }

  &.has-icon{
    &:before{
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: normal;
      //margin-right: 8px;
    }

    &__calendar-plus{
      &:before{
        content: '\f271';
      }
    }
    &__check{
      &:before{
        content: "\f00c";
      }
    }
    &__power{
      &:before{
        content: '\f011';
      }
    }
    &__plus{
      &:before {
        content: '\f067';
      }
    }
    &__share{
      &:before {
        content: '\';
      }
    }

    &__eye {
      &:before {
        content: '\f06e';
      }
    }
  }

  .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    opacity: 0;
    transition: all 0.5s ease-out;
    transition-property: transform, opacity;
    transform: scale(0) translate(0, 0);
    pointer-events: none;
  }

  .ico {
    margin-right: 6px;
  }

  .icon{
    &.icon-svg{
      line-height: 0.8;
      overflow: visible;
    }

    svg{
      max-width: 100%;
      height: 15px;
    }
  }

  > svg.icon{
    height: 15px;
  }

  &:focus {
    outline: 0;
  }

  &._italic{font-style: italic;}
  &._bold{font-weight: bold;}
  &._bold-italic{
    font-weight: bold;
    font-style: italic;
  }

  &.ajs-ok,
  &--primary,
  &-primary {
    background: $color-blue;
    border-color: $color-blue;
    color: #fff;
    @include break-min($mob) {
      font-weight: 500;
    }

    &:hover{
      background: darken($color-blue, 5%);
      border-color: $color-blue;
      color: #fff;
    }
    &:active{
      background: darken($color-blue, 7%);
      border-color: $color-blue;
      color: #fff;
    }
    &:focus{
      color: #fff;
    }
  }

  &--secondary{
    background: $color-orange;
    border: 1px solid $color-orange;
    color: $color-dark;
    @include break-min($mob){
      font-weight: 500;
    }

    &:hover{
      background: darken($color-orange, 5%);
      border: 1px solid $color-orange;
      color: $color-dark;
    }
    &:active{
      background: darken($color-orange, 7%);
      border: 1px solid $color-orange;
      color: $color-dark;
    }
    &:focus{
      color: $color-dark;
    }
  }

  &--success,
  &-success{
    background: $color-green;
    border-color: $color-green;
    color: $white;
    @include break-min($mob){
      font-weight: 500;
    }
    &:hover{
      background: darken($color-green, 5%);
      border-color: $color-green;
      color: $white;
    }
    &:active{
      background: darken($color-green, 7%);
      border-color: $color-green;
      color: $white;
    }
    &:focus{
      color: $white;
    }
  }

  &--success-outline,
  &-success-outline{
    background: transparent;
    border-color: $color-green;
    color: $color-green;
    @include break-min($mob){
      font-weight: 500;
    }
    &:hover{
      background: rgba($color-green, 0.1);
    }
    &:active{
      border: 1px solid darken($color-green, 7%);
      color: $color-green;
    }
    &:focus{
      border: 1px solid $color-green;
      color: $color-green;
    }
  }

  &--secondary-outline,
  &-secondary-outline{
    border: 1px solid $border-color;
    background: #fff;
    color: $color-dark;

    &:hover{
      border: 1px solid darken($border-color, 5%);
      color: $color-dark;
      background: rgba($secondary-brand-color, 0.3);
    }
    &:active{
      border: 1px solid darken($border-color, 7%);
      color: $color-dark;
    }
    &:focus{
      border: 1px solid $border-color;
      color: $color-dark;
    }

    &.secondary-outline_tooltip{
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      font-size: 14px;
      padding: 0;
      line-height: 1;
      border-radius: 50%;
      overflow: visible;

      .btn__txt{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        text-align: left;
        line-height: 1.2;
        font-size: 13px;
        white-space: normal;
        color: $color-dark;
        width: 126px;
        min-width: 126px;
        bottom: 32px;
        position: absolute;
        right: -15px;
        border-radius: $br;
        background: $white;
        padding: 8px 15px;
        box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 1002;

        &:after{
          content: '';
          position: absolute;
          top: 99%;
          right: 26px;
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 8px 7px 0 7px;
          border-color: $white transparent transparent transparent;
          transform: rotate(0deg);
        }
      }

      &:focus, &:active,
      &:hover{
        color: $color-dark;

        .btn__txt{
          visibility: visible;
          opacity: 1;
          bottom: 38px;
        }
      }
    }
  }

  &--action-secondary,
  &--action{
    background: $secondary-brand-color;
    border-color: $border-color;
    color: $color-dark;

    &:hover{
      background: darken($secondary-brand-color, 5%);
      border-color: $secondary-brand-color;
      color: $color-dark;
    }
    &:active{
      background: darken($secondary-brand-color, 7%);
      border-color: $secondary-brand-color;
      color: $color-dark;
    }
    &:focus{
      color: $color-dark;
    }
  }

  &--danger-outline{
    border:  1px solid transparent;
    background: #fff;
    color: $color-danger;

    &:hover{
      border:  1px solid transparent;
      color: $color-danger;
      background: rgba(#fff, 0.9);
    }
    &:active{
      border:  1px solid transparent;
      color: $color-danger;
    }
    &:focus{
      border: 1px solid  transparent;
      color: $color-danger;
    }
  }

  &--danger{
    border:  1px solid $color-danger;
    background: $color-danger;
    color: #fff;

    @include break-min($mob){
      font-weight: 500;
    }

    &.opacity {
      background: rgba($color-danger, 0.1);
      border:  1px solid rgba($color-danger, 0.05);
      color: $color-dark;
      &:hover,
      &:active,
      &:focus {
        border:  1px solid rgba($color-danger, 0.05);
        color: $color-dark;
        background: darken($color-danger, 3%);
      }
    }

    &:hover{
      border:  1px solid darken($color-danger, 3%);
      color: #fff;
      background: darken($color-danger, 3%);
    }
    &:active{
      border:  1px solid darken($color-danger, 5%);
      color: #fff;
      background: darken($color-danger, 5%);
    }
    &:focus{
      border:  1px solid darken($color-danger, 3%);
      color: #fff;
      background: darken($color-danger, 3%);
    }
  }

  &--link{
    border: none;
    color: $color-dark;
    background: transparent;

    &:hover{
      background: rgba($secondary-brand-color, 0.2%);
      color: $color-dark;
    }
    &:active{
      background: rgba($secondary-brand-color, 0.3%);
      color: $color-dark;
    }
  }

  &--link-primary{
    display: inline-flex;
    border: none;
    color: $color-blue;
    background: transparent;
    min-height: unset;
    height: auto;
    padding: 4px 0;
    //outline-offset: 4px;

    &:hover{
      background: transparent;
      text-decoration: underline;
      color: darken($color-blue, 6%);
    }
    &:active{
      background: transparent;
      text-decoration: underline;
      color: darken($color-blue, 8%);
    }
  }

  &--link-danger{
    display: inline-flex;
    border: none;
    color: $color-danger;
    background: transparent;
    min-height: unset;
    height: auto;
    padding: 4px;

    &:hover{
      background: transparent;
      text-decoration: underline;
      color: darken($color-danger, 6%);
    }
    &:active{
      background: transparent;
      text-decoration: underline;
      color: darken($color-danger, 8%);
    }
  }

  &--no-text{
    min-width: 36px;
    padding: 2px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    min-height: 36px;
    font-size: $main-fs;
    font-weight: $normal;
    line-height: 1;
    text-align: center;
    justify-content: center;
    background: transparent;

    &.btn--primary {
      color: $color-primary;
      border: 1px solid $color-primary;

      &:hover, &:focus {
        color: $color-white;
      }
    }

    &.btn--secondary-outline,
    &.btn--secondary {
      color: $color-neutral;
      border: none;

      &:hover{
        border: 1px solid darken($border-color, 5%);
        color: $color-dark;
        background: rgba($secondary-brand-color, 0.3);
      }
      &:active{
        border: 1px solid darken($border-color, 7%);
        color: $color-dark;
      }
      &:focus{
        border: 1px solid $border-color;
        color: $color-dark;
      }

      @include break-max($mob) {
        background: $color-secondary-50;
      }
    }

    &.btn--accented {
      color: $color-white;
      background: $color-neutral-dark;
    }

    &.btn--danger{
      color: $color-danger;
      border: 1px solid $color-danger;
      background: transparent;

      @include break-max($mob) {
        background: $color-secondary-50;
      }

      &:hover{
        border: 1px solid darken($color-danger, 5%);
        color: $color-danger;
        background: rgba($secondary-brand-color, 0.3);
      }
      &:active{
        border: 1px solid darken($color-danger, 7%);
        color: $color-danger;
      }
      &:focus{
        border: 1px solid $color-danger;
        color: $color-danger;
      }
    }

    &-on-mob{
      @include break-max($mob){
        min-width: 36px;
        padding: 2px;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        min-height: 36px;
        font-size: $main-fs;
        font-weight: $normal;
        line-height: 1;
        text-align: center;
        justify-content: center;
        background: transparent;

        .btn__txt{display: none;}

        &.btn--primary {
          color: $color-primary;
          border: 1px solid $color-primary;

          &:hover, &:focus {
            color: $color-white;
          }
        }

        &.btn--secondary-outline,
        &.btn--secondary {
          color: $color-neutral;
          border: none;

          &:hover{
            border: 1px solid darken($border-color, 5%);
            color: $color-dark;
            background: rgba($secondary-brand-color, 0.3);
          }
          &:active{
            border: 1px solid darken($border-color, 7%);
            color: $color-dark;
          }
          &:focus{
            border: 1px solid $border-color;
            color: $color-dark;
          }

          @include break-max($mob) {
            background: $color-secondary-50;
          }
        }

        &.btn--accented {
          color: $color-white;
          background: $color-neutral-dark;
        }

        &.btn--danger{
          color: $color-danger;
          border: 1px solid $color-danger;
          background: transparent;

          @include break-max($mob) {
            background: $color-secondary-50;
          }

          &:hover{
            border: 1px solid darken($color-danger, 5%);
            color: $color-danger;
            background: rgba($secondary-brand-color, 0.3);
          }
          &:active{
            border: 1px solid darken($color-danger, 7%);
            color: $color-danger;
          }
          &:focus{
            border: 1px solid $color-danger;
            color: $color-danger;
          }
        }
      }
    }
  }

  &.btn--back{
    border: none;
    background: transparent;
    color: $color-dark;
    padding-left: 0;

    .icon{
      font-size: 18px;
    }

    &:hover, &:focus, &:active{
      background: transparent;
      border: none;
      outline: transparent;
      color: $color-dark;
    }
  }

  &--action-tooltip{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 14px;
    padding: 0;
    line-height: 1;
    background: rgba(#fff, 0.5);
    color: $color-icons;
    border: none;
    border-radius: 50%;
    overflow: visible;

    .btn__txt{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
      text-align: left;
      line-height: 1.2;
      font-size: 13px;
      white-space: normal;
      color: $color-dark;
      width: 126px;
      min-width: 126px;
      bottom: 32px;
      position: absolute;
      right: -15px;
      border-radius: $br;
      background: $white;
      padding: 8px 15px;
      box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      z-index: 1002;

      &:after{
        content: '';
        position: absolute;
        top: 99%;
        right: 26px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 8px 7px 0 7px;
        border-color: $white transparent transparent transparent;
        transform: rotate(0deg);
      }
    }

    &:focus, &:active,
    &:hover{
      color: $color-dark;
      background: rgba($secondary-brand-color, 0.35);

      .btn__txt{
        visibility: visible;
        opacity: 1;
        bottom: 38px;
      }
    }

    &.action-tooltip-danger{
      &:hover{
        color: $color-danger;
      }
    }
  }

  &.theme-toggler{
    text-shadow: 0px 0px 5px rgba($color-warning, 0.63);

    .icon{font-size: 20px;}

    svg{
      height: 22px;
      line-height: 0.8;
    }

    &.white-mode{
      background: $color-neutral-dark;
      border-color: $color-neutral-dark;
      color: $color-neutral--dark;

      &:hover, &:focus, &:active{
        border-color: $color-neutral-dark;
        text-shadow: 0px 0px 8px rgba($color-warning, 0.33);
        background: darken($color-neutral-dark, 6%);
        color: $color-neutral--dark;
      }
    }

    &.dark-mode{
      color: $color-warning--dark;
      text-shadow: 0px 0px 8px rgba(#000, 0.83);
      svg{
        color: $color-warning--dark;
      }
    }
  }

  &.btn--location{
    border-radius: $br;
    border: 1px solid $border-color;
    min-height: 96px;
    min-width: 78px;
    max-width: 82px;
    flex: 1;
    padding: 25px 6px 12px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    color: $color-neutral-dark;
    overflow: hidden;
    gap: 8px;
    line-height: 1.6;
    background: $white;

    .btn__txt{
      font-size: $small-text;
      font-weight: normal;
      white-space: normal !important;
    }

    .caret,
    .icon{
      margin: 0;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      min-height: 24px;
      order: -3;
      text-align: center;

      .icon{
        transition: all 0.22s ease-out;
        font-size: 18px;
        font-weight: 300;
      }

      img{
        max-width: 100%;
        height: 24px;
        min-height: 24px;
      }
    }

    &.btn--dropdown{
      border: none;
      height: 100%;
    }

    &:hover:not(.btn--dropdown){
      border-color: $color-blue;
    }

    &[data-active="true"]:not(.btn--dropdown){
      border-color: $color-blue;
      background: $secondary-brand-color;
    }
  }
}

.has-icon__share{
  &:before {
    content: '\';
  }
}

.btn--preview{
  &.has-icon{
    @include break-max($mob){
      padding: 0;
      width: 36px;
      color: $color-icons;
    }
    .txt{
      display: none;
      @include break-min($mob){
        display: inline-block;
      }
    }
    &:before{
      color: $color-icons;
      margin: 0;
      @include break-min($mob){
        margin-right: 8px;
      }
    }
  }

}

.dropdown{
  .actions__item{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: rgba($secondary-brand-color, 0.1);
    transition: all 0.2s ease-out;
    color: $color-icons;
    //border: 1px solid red;

    &.zmdi-more-vert{
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      font-size: 15px;

      &:before{
        content: '\f142';
      }
    }

    &:hover{
      color: $color-dark;
      background: rgba($secondary-brand-color, 0.5);
    }
    &:active{
      background: rgba($secondary-brand-color, 0.9);
    }
  }
}

.btn--action{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 36px;
  font-size: 14px;
  padding: 0;
  line-height: 1;
  background: rgba(#fff, 0.5);
  color: $color-icons;
  border: none;
  border-radius: 50%;

  .btn__txt,
  .actions__item-txt{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    line-height: 1.2;
    font-size: 13px;
    white-space: normal;
    color: $color-dark;
    width: 130px;
    min-width: 130px;
    bottom: 35px;
    position: absolute;
    right: -15px;
    border-radius: $br;
    background: $white;
    padding: 8px 15px;
    box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 100;

    &:after{
      content: '';
      position: absolute;
      top: 99%;
      right: 26px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 8px 7px 0 7px;
      border-color: $white transparent transparent transparent;
      transform: rotate(0deg);
    }
  }

  &:hover{
    color: $color-dark;
    background: rgba($secondary-brand-color, 0.35);

    .actions__item-txt{
      visibility: visible;
      opacity: 1;
      bottom: 42px;
    }
  }

  &-danger,
  &-remove{
    &:hover{
      color: $color-danger;
    }
  }
}

.dropdown__mobile-bar,
.dropdown__mob-bar{
  .btn--action{
    @include break-max($mob){
      text-align: left;
      width: 100%;
      font-weight: 400;
      font-family: $mainFont;
      font-size: 16px;
      display: block;
      padding: 7px 0;
      line-height: 1.2;
      height: auto;
      margin: 0 0 3px;
      background: transparent;
      background-color: transparent;

      i{
        margin-right: 10px;
        min-width: 20px;
        text-align: center;
      }

      .actions__item-txt{
        display: inline;
        font-size: inherit;
        position: static;
        padding: 0;
        margin: 0;
        box-shadow: none;
        background: unset;
        opacity: unset;
        visibility: unset;
        transition: unset;

        &:after{
          display: none;
        }
      }
    }
  }

  &.--to-mob-on__mob-xl{
    .btn--action{
      @include break-max($mob--xl){
        text-align: left;
        width: 100%;
        font-weight: 400;
        font-family: $mainFont;
        font-size: 16px;
        display: block;
        padding: 7px 0;
        line-height: 1.2;
        height: auto;
        margin: 0 0 3px;
        background: transparent;
        background-color: transparent;

        i{
          margin-right: 10px;
          min-width: 20px;
          text-align: center;
        }

        .actions__item-txt{
          display: inline;
          font-size: inherit;
          position: static;
          padding: 0;
          margin: 0;
          box-shadow: none;
          background: unset;
          opacity: unset;
          visibility: unset;
          transition: unset;

          &:after{
            display: none;
          }
        }
      }
    }
  }
}

.caret {
  margin-left: 4px;
  font-size: $small-text;
}



/// -------------- DARK MODE --------------- ///
.dark-mode{

  .react-confirm-alert-body-element .sbpay-btn,
  .sbpay-widget .sbpay-btn,

  .ajs-button,
  .btn {
    font-weight: normal;

    &.ajs-ok,
    &--primary,
    &-primary {
      background: $color-primary--dark;
      border-color: $color-primary--dark;
      color: #fff;
      text-shadow: 0px 0px 5px rgba(#000, 0.45);
      @include break-min($mob) {
        font-weight: normal;
      }

      &:hover{
        background: darken($color-primary--dark, 2%);
        border-color: $color-primary--dark;
        color: #fff;
      }
      &:active{
        background: darken($color-primary--dark, 3%);
        border-color: $color-primary--dark;
        color: #fff;
      }
    }

    &--secondary{
      background: darken($color-orange, 6%);
      border-color: darken($color-orange, 6%);

      &:hover{
        background: darken($color-orange, 14%);
        border-color: $color-orange;
      }
      &:active{
        background: darken($color-orange, 16%);
        border-color: $color-orange;
      }
    }

    &--success,
    &-success{
      background: darken($color-green, 18%);
      border-color: darken($color-green, 18%);

      &:hover{
        background: darken($color-green, 20%);
        border-color: darken($color-green, 20%);
      }
      &:active{
        background: darken($color-green, 22%);
        border-color: darken($color-green, 22%);
      }
    }

    &--success-outline,
    &-success-outline{
      background: transparent;
      border-color: darken($color-green, 14%);
      color: darken($color-green, 14%);

      &:hover{
        background: rgba($color-green, 0.1);
      }
      &:active{
        border-color: darken($color-green, 16%);
        color: darken($color-green, 16%);
      }
      &:focus{
        border-color: darken($color-green, 16%);
        color: darken($color-green, 16%);
      }
    }

    &--secondary-outline,
    &-secondary-outline{
      border-color: $color-border--dark;
      color: $color-neutral--dark;
      background: rgba(#fff, 0.12);
      text-shadow: 0px 0px 5px rgba(#000, 0.55);

      &:hover, &:focus{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }

      &:active{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }

      &.secondary-outline_tooltip{
        border-color: $color-border--dark;
        .btn__txt{

          color: $color-second-text-color--dark;
          background: $body-bg--dark;

          &:after{
            border-color: $body-bg--dark transparent transparent transparent;
          }
        }

        &:focus, &:active,
        &:hover{
          border-color: $color-border--dark;
          color: $color-neutral--dark;
        }
      }
    }

    &--action-secondary,
    &--action{
      border-color: $color-border--dark;
      color: $color-neutral--dark;
      background: rgba(#fff, 0.12);

      &:hover, &:focus{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }

      &:active{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }
    }

    &--danger-outline{
      border-color: transparent;
      background: rgba(#fff, 0.12);
      color: $color-danger--dark;
      text-shadow: 0px 0px 5px rgba(#000, 0.55);

      &:hover{
        border-color: transparent;
        color: $color-danger--dark;
        background: rgba(#fff, 0.26);
      }

      &:active{
        border-color: transparent;
        background: rgba(#fff, 0.26);
        color: $color-danger--dark;
      }

      &:focus{
        border-color:  transparent;
        background: rgba(#fff, 0.26);
        color: $color-danger--dark;
      }
    }

    &--danger{
      border-color:  $color-danger--dark;
      background: $color-danger--dark;
      color: #fff;
      text-shadow: 0px 0px 5px rgba(#000, 0.55);

      &.opacity {
        background: rgba($color-danger--dark, 0.1);
        border-color: rgba($color-danger--dark, 0.05);
        color: $color-neutral--dark;
        text-shadow: 0px 0px 5px rgba(#000, 0.4);

        &:hover,
        &:active,
        &:focus {
          border-color: rgba($color-danger--dark, 0.05);
          color: $color-neutral--dark;
          background: darken($color-danger--dark, 3%);
        }
      }

      &:hover{
        border-color: darken($color-danger--dark, 3%);
        color: $color-neutral--dark;
        background: darken($color-danger--dark, 3%);
      }

      &:active{
        border-color: darken($color-danger--dark, 5%);
        color: $color-neutral--dark;
        background: darken($color-danger--dark, 5%);
      }

      &:focus{
        border-color: darken($color-danger--dark, 3%);
        color: $color-neutral--dark;
        background: darken($color-danger--dark, 3%);
      }
    }

    &--link{
      border: none;
      color: $color-second-text-color--dark;
      background: transparent;

      &:hover{
        background: rgba($second-brand-color--dark, 0.3%);
        color: $color-neutral--dark;
      }

      &:active{
        background: rgba($second-brand-color--dark, 0.4%);
        color: $color-neutral--dark;
      }
    }

    &--link-primary{
      color: $color-primary--dark;

      &:hover{
        background: transparent;
        text-decoration: underline;
        color: darken($color-primary--dark, 4%);
      }

      &:active{
        color: darken($color-primary--dark, 6%);
      }
    }

    &--link-danger{
      color: $color-danger--dark;

      &:hover{
        color: darken($color-danger--dark, 6%);
      }

      &:active{
        color: darken($color-danger--dark, 8%);
      }
    }

    &--no-text{
      background: rgba(#fff, 0.12);

      &.btn--primary {
        color: $color-primary--dark;
        border-color: $color-primary--dark;

        &:hover, &:focus {
          color: $color-primary--dark;
        }
      }

      &.btn--secondary-outline,
      &.btn--secondary {
        color: $color-second-text-color--dark;

        &:hover{
          border-color: darken($color-border--dark, 5%);
          color: $color-neutral--dark;
          background: rgba(#fff, 0.26);
        }

        &:active{
          border-color: darken($color-border--dark, 7%);
          color: $color-neutral--dark;
          background: rgba(#fff, 0.26);
        }
        &:focus{
          border-color: darken($color-border--dark, 7%);
          color: $color-neutral--dark;
          background: rgba(#fff, 0.26);
        }

        @include break-max($mob) {
          background: rgba(#fff, 0.26);
        }
      }

      &.btn--accented {
        color: $color-white;
        background: $color-neutral-dark;
      }

      &.btn--danger{
        color: $color-danger--dark;
        border-color: $color-danger--dark;
        background: rgba(#fff, 0.12);

        @include break-max($mob) {
          background: rgba(#fff, 0.12);
        }

        &:hover{
          border-color: darken($color-danger--dark, 5%);
          color: $color-danger--dark;
          background: rgba(#fff, 0.26);
        }

        &:active{
          border-color: darken($color-danger--dark, 7%);
          color: $color-danger--dark;
          background: rgba(#fff, 0.26);
        }

        &:focus{
          border-color: $color-danger--dark;
          color: $color-danger--dark;
        }
      }

      &-on-mob{
        @include break-max($mob){
          background: rgba(#fff, 0.12);

          &.btn--primary {
            color: $color-primary--dark;
            border-color: $color-primary--dark;

            &:hover, &:focus {
              color: $color-primary--dark;
            }
          }

          &.btn--secondary-outline,
          &.btn--secondary {
            color: $color-second-text-color--dark;

            &:hover{
              border-color: darken($color-border--dark, 5%);
              color: $color-neutral--dark;
              background: rgba(#fff, 0.26);
            }

            &:active{
              border-color: darken($color-border--dark, 7%);
              color: $color-neutral--dark;
              background: rgba(#fff, 0.26);
            }
            &:focus{
              border-color: darken($color-border--dark, 7%);
              color: $color-neutral--dark;
              background: rgba(#fff, 0.26);
            }

            @include break-max($mob) {
              background: rgba(#fff, 0.26);
            }
          }

          &.btn--accented {
            color: $color-white;
            background: $color-neutral-dark;
          }

          &.btn--danger{
            color: $color-danger--dark;
            border-color: $color-danger--dark;
            background: rgba(#fff, 0.12);

            @include break-max($mob) {
              background: rgba(#fff, 0.12);
            }

            &:hover{
              border-color: darken($color-danger--dark, 5%);
              color: $color-danger--dark;
              background: rgba(#fff, 0.26);
            }

            &:active{
              border-color: darken($color-danger--dark, 7%);
              color: $color-danger--dark;
              background: rgba(#fff, 0.26);
            }

            &:focus{
              border-color: $color-danger--dark;
              color: $color-danger--dark;
            }
          }
        }
      }
    }

    &.btn--back{
      border: none;
      background: transparent;
      color: $color-neutral--dark;

      &:hover, &:focus, &:active{
        background: transparent;
        border: none;
        outline: transparent;
        color: $color-second-text-color--dark;
      }
    }

    &--action-tooltip{
      background: rgba(#fff, 0.12);
      color: $color-second-text-color--dark;

      .btn__txt{
        color: $color-second-text-color--dark;
        background: $body-bg--dark;
        @include shadowDarkMode;

        &:after{
          border-color: $body-bg--dark transparent transparent transparent;
        }
      }

      &:focus, &:active,
      &:hover{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
      }

      &.action-tooltip-danger{
        &:hover{
          color: $color-danger--dark;
        }
      }
    }

    &.collapse__toggler-track-state.btn--no-text[aria-expanded="true"],
    &.collapse__toggler-track-state[aria-expanded="true"] {
      border-color: $color-warning--dark;
      background: $input-bg--dark;
      color: $color-neutral--dark;

      &:hover, &:focus, &:active {
        background: $input-bg--dark;
        border-color: $color-warning--dark;
        color: $color-neutral--dark;
      }
    }

    &:disabled,
    &--disabled{
      background: #333333;
      border-color: #333333;
      color: #686868;
      cursor: not-allowed;

      .icon, .ico{
        color: #686868;
      }

      &:hover, &:focus{
        background: #333333;
        border-color: #333333;
        color: #686868;
      }
    }

    &.btn--location{
      border-color: $color-border--dark;
      color: $color-neutral--dark;
      background: $input-bg--dark;

      &:hover:not(.btn--dropdown){
        border-color: $color-primary--dark;
      }

      &[data-active="true"]:not(.btn--dropdown){
        border-color: $color-primary--dark;
        background: $sidebar-bg--dark;
      }
    }



    //Billing widget

    &.sbpay-btn--brand{
      background: darken($color-orange, 6%);
      border-color: darken($color-orange, 6%);
      color: $color-neutral-dark;

      &:hover{
        background: darken($color-orange, 14%);
        border-color: $color-orange;
        color: $color-neutral-dark;
      }
      &:active{
        background: darken($color-orange, 16%);
        border-color: $color-orange;
        color: $color-neutral-dark;
      }
    }

    &.sbpay-btn--secondary{
      border-color: $color-border--dark;
      color: $color-neutral--dark;
      background: rgba(#fff, 0.12);
      text-shadow: 0px 0px 5px rgba(#000, 0.55);

      &:hover, &:focus{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }

      &:active{
        color: $color-neutral--dark;
        background: rgba(#fff, 0.26);
        border-color: $color-border--dark;
      }
    }

    &.sbpay-btn--success {
      background: darken($color-green, 18%);
      border-color: darken($color-green, 18%);

      &:hover{
        background: darken($color-green, 20%);
        border-color: darken($color-green, 20%);
      }
      &:active{
        background: darken($color-green, 22%);
        border-color: darken($color-green, 22%);
      }
    }

    &.sbpay-btn--back {
      border: none;
      background: transparent;
      color: $color-neutral--dark;

      &:hover, &:focus, &:active{
        background: transparent;
        border: none;
        outline: transparent;
        color: $color-second-text-color--dark;
      }
    }
  }

  .btn--action{
    .btn__txt,
    .actions__item-txt{
      color: $color-second-text-color--dark;
      background: $body-bg--dark;
      @include shadowDarkMode;

      &:after{
        border-color: $body-bg--dark transparent transparent transparent;
      }
    }
  }
  .btn--action-danger:hover,
  .btn--action-remove:hover{
    color: $color-danger--dark;

    i,
    .ico,
    .icon{
      color: $color-danger--dark;
    }
  }


  // billing widget
  .sbpay-widget .sbpay-btn{
    &:disabled{
      color: #757575 !important;
      background: lighten($bg-disabled-status, 3%) !important;
      border-color: lighten($bg-disabled-status, 6%) !important;
      cursor: not-allowed;
    }
  }
}

