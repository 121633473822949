.password-field-component{

	.view{
		position: relative;

		.input-group{
			input[type=password]{
				padding-right: 44px;
				border-radius: 4px;
			}

			.form-control:not(:first-child), {
				border-bottom-left-radius: 4px;
				border-top-left-radius: 4px;
			}

			.show-pass{
				position: absolute;
				top: 0;
				right: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 40px;
				color: $color-icons;
				font-size: 14px;
				cursor: pointer;
				z-index: 4;



				&.--is-visible{
					color: $color-icons;
				}
			}
		}
	}
}

.password-power{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 14px 16px;
	gap: 10px;
	position: absolute;
	width: 250px;
	left: auto;
	top: 110%;
	color: $color-neutral-dark;
	background: #FFFFFF;
	border: 1px solid $border-color;
	box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
	border-radius: 6px;
	font-size: $main-text;
	opacity: 0.5;
	visibility: hidden;
	transform: translate(0px, -40px);
	transition: all 0.5s ease-out;
	z-index: 99;

	&.--is-in{
		visibility: visible;
		transform: translate(0px, 0px);
		opacity: 1;
	}

	.progress-bar-danger {
		background-color: $color-red;
	}
	.progress-bar-warning{
		background-color: $color-warning;
	}
	.progress-bar-success{
		background-color: $color-success;
	}

	&--text-type{
		display: none;
	}

	#popover-password{
		p{
			margin: 0;
		}
	}

	&--title{
		display: flex;
		flex-wrap: wrap;
		gap: 0 4px;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 10px;
		line-height: 1.2;
	}

	.progress {
		position: relative;
		height: 4px;
		margin-bottom: 10px;
		overflow: hidden;
		background-color: #D9D9D9;
		border-radius: 10px;

		.progress-bar{
			position: absolute;
			top: 0;
			left: 0;
			height: 4px;
			transition: width .7s ease;
		}
	}

	&--check-list{
		li{
			span{
				font-size: $small-text;
				color: $color-neutral;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0 6px;
				font-style: normal;
			}
		}

		.fa-circle{
			font-size: 5px;
			color: #D9D9D9;
		}
		.fa-check{
			font-size: 10px;
			color: $color-success;
		}
	}
}