.dark-mode{
  .alert{
    color: $color-neutral--dark;

    &-dismissible{
      .close{
        background: transparent;
        color: $color-second-text-color--dark;

        &:hover{
          color: $color-danger--dark;
          background: transparent;
        }
      }
    }

    &-danger {
      background-color: $alert-danger--dark;
      border-color: $alert-danger-border--dark;
      color: $color-neutral--dark;
    }

    &-warning {
      background-color: $alert-warning--dark;
      border-color: $alert-warning-border--dark;
      color: $color-second-text-color--dark;
    }

    &-success {
      background-color: $alert-success--dark;
      border-color: $alert-success-border--dark;
      color: $color-second-text-color--dark;
    }

    &-info{
      border-left: 3px solid $color-warning--dark;
    }

    &-iso {
      background: rgba($alert-warning-border--dark, 0.8);
      border-color: $alert-warning--dark;
    }
  }

  .iso-block{
    background: rgba($alert-warning-border--dark, 0.8);
    border-color: $alert-warning--dark;
    color: $color-second-text-color--dark;

    &--picture{
      img{
        //@include makeImageSoft;
      }
    }
  }

  //trial bar
  .trial-bar {
    color: $white;
  }
}

[class*="alert-"] {}

.alert{
  position: relative;
  display: flex;
  align-items: baseline;
  gap: 6px 12px;
  min-height: 44px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  border-radius: $br;
  line-height: 1.36;
  margin-bottom: 10px;
  font-style: italic;
  font-size: $alert-text;
  color: $color-dark;

  //position: fixed;
  //width: 100%;
  //top: 70px;
  //z-index: 999;

  &:last-child{margin-bottom: 24px;}

  &.mb-0{margin-bottom: 0;}
  &.mb-20{margin-bottom: 20px;}

  a{
    display: inline-flex;
    margin-left: 4px;
    margin-right: 4px;
  }

  p,
  .p{
    flex-grow: 1;
    flex-basis: 250px;
    font-size: $alert-text;
  }

  .grid{
    .p{
      flex-basis: unset;
    }
  }

  &-dismissible{
    padding-right: 64px;

    .close{
      position: absolute;
      top: 3px;
      right: 15px;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      min-width: 36px;
      font-size: $main-fs;
      font-weight: 300;
      line-height: 1;
      border-radius: 50%;
      background: rgba(#fff, 0.1);
      color: $color-grey;
      transition: all 0.2s ease;

      span{
        display: none;
      }

      &:hover{
        background: rgba(#fff, 0.3);
        color: $color-red;
      }
    }
  }

  &:before{
    position: relative;
    display: inline-block !important;
    min-width: 18px;
    margin: 0;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    font-size: 15px;
    top: 0px;
  }

  &-danger {
    background-color: #ffeaee;
    border-color: #ffeaee;
    color: $color-dark;
    &:before{
      content: '\f071';
      color: $color-red;
    }
  }

  &-warning {
    background-color: #fff3cd;
    border-color: #fff3cd;
    color: $color-dark;
    &:before{
      content: '\f071';
      color: $color-orange;
    }
  }

  &-success {
    background-color: #e5fbf2;
    border-color: #e5fbf2;
    color: $color-dark;
    &:before{
      content: '\f00c';
      color: $color-green;
    }
  }

  &-info{
    padding: 4px 0 4px 10px;
    border-left: 3px solid $color-marketing;
    border-radius: 0px;
    min-height: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  &-iso {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    background: #FFF8EA;
    border-radius: 16px;
    border-color: #fff3cd;
    font-style: normal;

    &.alert-dismissible{
      padding-right: 58px;
    }
  }
}

.alert--validation-error{
  flex-wrap: wrap;

  .title--h3{
    font-size: $main-fs;
    line-height: 1;
  }

  .list{
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 8px;
    width: 100%;

    &-item{
      &__caption{
        min-width: 50px;
      }
    }
  }
}

// Notify -- alertify-notifier > ajs-message
body{
  .alertify-notifier.ajs-top {
    top: 68px;
    left: auto;
    width: 96%;
    max-width: 460px;

    @include break-min(600px){
      max-width: 360px;
    }
  }

  .alertify-notifier{
    width: 100%;
    transform: none;
    text-align: center;
    display: flex;
    flex-direction: column;

    &.ajs-center{
      left: 0;

      .ajs-message {
        transform: translateX(0);
        left: auto;
        right: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.ajs-right{
      .ajs-message{
        right: -100%;

        &.ajs-visible{
          right: 0 !important;
        }
      }
    }


    .ajs-message.ajs-visible,
    .ajs-message{
      width: 100%;
      max-width: 460px;
      margin: 0 !important;
      padding: 6px 16px 10px 20px;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 4px;

      text-align: left;
      font-style: normal;
      font-size: $main-text;
      font-weight: $normal;
      font-family: $mainFont;
      line-height: 1.6;
      color: $color-dark;

      background: $color-white;
      border-radius: $br-tab-item;
      @include shadowWhiteMode;

      &:before{
        position: absolute;
        left: 6px;
        top: 6px;
        content: '';
        display: block;
        width: 4px;
        height: calc(100% - 14px);
        background: $color-financial;
        border-radius: $br;
      }

      &.ajs-success{
        &:before{
          background: $color-green;
        }

        .bold{
          color: $color-green;
        }
      }

      &.ajs-error{
        &:before{
          background: $color-red;
        }

        .bold{
          color: $color-red;
        }
      }

      @include break-min(600px){
        max-width: 360px;
        padding: 6px 14px 6px 22px;
      }
    }
  }

  .alertify-notifier.ajs-center .ajs-message.ajs-visible{
    left: auto;
    right: auto;
    transform: translateX(0);
  }

  .alertify .ajs-dialog {
    position: relative;
    padding: 25px;
    outline: 0;
    border-radius: $alert-popup-br;
    background-color: #fff;

    .ajs-header {
      border-radius: $alert-popup-br $alert-popup-br 0 0;
    }

    @include break-max($mob){
      width: 100%;
      margin: 0;
      max-width: unset;
      border-radius: 0;

      .ajs-header {
        border-radius: 0;
      }
    }
  }

  .alertify .ajs-header {
    position: relative;
    font-size: $modal-title;
    font-family: $mainFont;
    color: $color-dark;
    margin-bottom: 12px;
    font-weight: $bold-modal-title;

    &:after{
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-primary;
    }

    @include break-max($mob){
      padding-top: 50px;
      text-align: center;
      &:after{
        bottom: auto;
        top: 40px;
      }
    }
  }

  .alertify .ajs-commands {
    margin: -14px 15px 0 0;
    @include break-max($mob){
      left: 0;
      top: 0;
      right: auto;
      margin: 0;
    }
  }

  .alertify .ajs-body .ajs-content{
    padding: 12px 0 20px 0;
  }
  .alertify .ajs-footer{
    padding: 0 25px;
  }
  .alertify .ajs-commands button.ajs-close{
    position: relative;
    top: 0;
    right: 0;
    height: 36px;
    width: 36px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none;

    &:before, &:after{
      content: '';
      display: inline-block;
      position: absolute;
      background: $color-icons;
      height: 2px;
      width: 14px;
      border-radius: 3px;
      opacity: 1;
      top: 18px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &:before{
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

    @include break-max($mob){
      &:after{
        display: none;
      }
      &:before{
        position: relative;
        margin: 0;
        padding: 0;
        top: 0;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 6 Pro";
        font-weight: 300;
        font-size: 20px;
        content: '\f30a';
        transform: none;
        width: auto;
        height: auto;
        background: #fff;
      }
      &:hover{
        &:before {
          background: #fff;
          color: $color-red;
        }
      }
    }
  }

  .alertify-notifier_top-right--small{
    left: auto;
    width: 96%;
    max-width: 460px;
    @include break-min(600px){
      max-width: 310px;
    }
  }

  &.dark-mode{
    .alertify-notifier{
      .ajs-message{
        color: $color-second-text-color--dark;

        &.ajs-success{
          background: $alert-success--dark;
          &:before{
            color: $color-success--dark;
          }
        }
        &.ajs-error{
          background: $alert-danger--dark;
          color: $color-second-text-color--dark;
          &:before{
            color: $color-danger--dark;
          }
        }
      }
    }

    .alertify .ajs-dialog {
      background-color: $body-bg--dark;
    }

    .alertify .ajs-header {
      color: $color-neutral--dark;
      &:after{
        background: $color-warning--dark;
      }
    }

    .alertify .ajs-commands button.ajs-close{
      &:before, &:after{
        background: $color-danger--dark;
      }

      @include break-max($mob){
        &:before{
          background: $body-bg--dark;
        }
        &:hover{
          &:before {
            background: $body-bg--dark;
            color: $color-danger--dark;
          }
        }
      }
    }

    .alertify-notifier_top-right--small{
      .ajs-message.ajs-visible{
        color: $color-neutral--dark;
        background: $sidebar-bg--dark;
        @include shadowDarkMode;

        &:before{
          background: $color-success--dark;
        }
      }
    }
  }
}

.iso-block{
  background: #FFF8EA;
  border-radius: 16px;
  border-color: #fff3cd;
  color: $color-dark;
  padding: 20px 25px;
  margin: 5px auto 22px;
  width: 100%;
  max-width: 740px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 10px 22px;

  @include break-max(600px){
    border-radius: 0;
    margin-right: -20px;
    margin-left: -20px;
    width: auto;
    max-width: unset;
  }

  &--picture{
    display: block;
    text-align: center;
    img{
      min-width: 63px;
      max-width: 63px;
    }
  }
}

//trial bar
.trial-bar {
  padding: 10px 20px;
  border-radius: $br;
  line-height: 1.36;
  margin-bottom: 10px;
  font-size: $alert-text;
  color: $white;
  text-shadow: 0px 0px 4px rgba($color-dark, 0.2);

  &--success {
    background: $success-gradient;
  }
  &--warning {
    background: $warning-gradient;
  }
  &--danger {
    background: $danger-gradient;
  }
}


// tutorial informer
.informer--getting-started{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  @include break-min(700px){
    align-items: baseline;
  }

  &_wrapper{
    position: fixed;
    top: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 20px 25px;
    bottom: auto;
    width: 96%;
    max-width: 675px;
    border-radius: $br-tab-item;
    background: $color-dark;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.07),
                0px 12px 16px 0px rgba(0, 0, 0, 0.07),
                0px 8px 8px 0px rgba(0, 0, 0, 0.07),
                0px 4px 4px 0px rgba(0, 0, 0, 0.07),
                0px 2px 2px 0px rgba(0, 0, 0, 0.07);
    z-index: 999999;
    color: $color-neutral--dark;

    &.hidden{display: none;}
    &.in{display: block;}

    @include break-min(700px){
      padding: 16px 24px;
    }
  }

  &_content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px 20px;
    flex: 1;
    text-align: center;

    @include break-min(700px){
      flex-direction: row;
      text-align: left;
      flex-wrap: wrap;
    }
  }

  &_btn-bar{
    display: flex;
    align-items: center;

    .btn{
      min-height: 30px;
    }
  }

  &_bird{
    display: inline-block;
    line-height: 0.6;
    margin-left: -15px;
    z-index: 9;

    @include break-min(700px){
      margin-top: -4px;
      margin-bottom: -14px;
    }
  }

  .close-gs-informer{
    position: relative;
    border: none !important;
    background: transparent;
    color: $color-white;
    margin-right: -18px;
    top: -12px;

    @include break-max(700px){
      position: absolute;
      top: 0;
      right: 0;
      margin-right: auto;
    }

    &:hover, &:focus, &:active{
      background: rgba(#fff, 0.3);
      color: $color-red;
    }
  }

  .title, .p{
    color: $color-neutral--dark;
  }

  .title{
    font-weight: bolder;
    flex: 1;
  }

  .p--primary{
    width: 100%;
    text-wrap: balance;
    //flex: 1;
  }
}

.page--welcome-tour{
  .informer--getting-started_wrapper.in,
  .informer--getting-started_wrapper{
    display: none !important;
    visibility: hidden;
  }
}
