.dark-mode{
  .toggle-switch__helper:before{
    border-color: $color-border--dark;
    background: $input-bg--dark;
  }
  .toggle-switch__helper:after{
    background: #bfbfbf;
    //top: 2px;
  }
  .toggle-switch__checkbox:checked ~ .toggle-switch__helper{
    &:before{
      border-color: $alert-warning-border--dark; //#967100;
    }
    &:after{
      background: $color-primary;
    }
  }
}

.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6f7ff;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #1890ff;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}


.toggle-switch {
  display: inline-block;
  width: 46px;
  height: 26px;
  position: relative;

}

.toggle-switch__helper {
  position: absolute;
  height: 100%;
  width: 100%;

  &:before,
  &:after {
    will-change: left, background-color;
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
    //@include transition(left 200ms, background-color, 200ms);
  }

  &:before {
    background-color: #fff;
    border: 1px solid $border-color;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  &:after {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #D5E0F0;
    left: 4px;
    top: 3px;
    box-shadow: none;
    z-index: 1;
  }
}

.toggle-switch__checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked {
    & ~ .toggle-switch__helper{
      &:after {
        left: calc(100% - 23px);
        background-color: $color-blue;
      }
    }
  }

  &:disabled {
    & ~ .toggle-switch__helper {
      opacity: 0.65;
    }
  }

  &:active {
    & ~ .toggle-switch__helper:after {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
    }
  }
}

// Theming
@mixin toggle-switch-theme($color) {
  .toggle-switch__checkbox {
    &:checked {
      & ~ .toggle-switch__helper{
        &:after {
          background-color: $color;
        }
      }
    }
  }
}