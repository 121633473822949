html body.dark-mode{

    .react-select__menu-list {
        @include scroll-style--dark(10px);
    }

    .react-select{
        &__menu{
            color: $color-second-text-color--dark;
            background: $body-bg--dark;
            @include shadowDarkMode
        }

        &__menu-list{
            .react-select__group{
                border-color: $color-border--dark;
            }
        }

        &__option{
            background: $body-bg--dark;
            color: $color-second-text-color--dark;

            &--is-focused{
                background: $second-brand-color--dark;
                color: $color-neutral--dark;
            }
            &--is-selected{
                background: $sidebar-bg--dark;
                color: $color-neutral--dark;
            }

            &--is-disabled{
                color: $color-disabled-text--dark;
                background: $alert-warning--dark;

                &.react-select__option--is-focused{
                    color: $color-disabled-text--dark;
                    background: $alert-warning--dark;
                }
            }
        }

        &__group-heading{
            //border-color: $color-border--dark;
            color: $color-neutral--dark;
        }
    }
    .css-4avucx-control,
    .css-3wv91y-control{
        border-color: $color-border--dark;
        background-color: $input-bg--dark;
    }
    .css-jtaxry-option:active {
        background-color: $second-brand-color--dark;
    }


    .Select-menu-outer {
        background-color: #fff;
    }

    .Select-option{
        background: $body-bg--dark;
        color: $color-second-text-color--dark;

        &.is-focused{
            background: $second-brand-color--dark;
            color: $color-neutral--dark;
        }

        &.is-selected{
            background: $sidebar-bg--dark;
            color: $color-neutral--dark;
        }
    }
}

html body{
    .date-format-select-field {
        .react-select {
            &__option {
                &:first-letter {
                    text-transform: none;
                }
            }
        }
    }
    .react-select{
        &__control {}

        &__menu{
            border-radius: $br;
            background: #fff;
            box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
            z-index: 100;
        }

        &__menu-list{
            max-height: 240px;

            .react-select__group{
                padding-top: 14px;
                padding-bottom: 14px;
                border-top: 1px solid $border-color;

                &:first-child{
                    border-top: none;
                    padding-top: 8px;
                }

                &:last-child{
                    padding-bottom: 8px;
                }
            }
        }

        &__option{
            font-family: $mainFont;
            cursor: pointer;
            background: #fff;
            padding: 8px 16px;
            color: $color-dark;
            font-size: $main-text;
            font-weight: normal;

            &:first-letter {
                text-transform: uppercase;
            }


            &--is-focused{
                background: rgba($secondary-brand-color, 0.5);
                color: $color-dark;
                padding: 8px 16px;
            }
            &--is-selected{
                background: $color-blue;
                color: #fff;
            }

            &--is-disabled{
                color: $color-icons;
                background: $lite-orange;
                font-style: italic;

                &.react-select__option--is-focused{
                    color: $color-icons;
                    background: $lite-orange;
                }
            }
        }

        &__group{
            //padding-top: 10px;
        }

        &__group-heading{
            border-top: none;
            color: $color-neutral;
            font-size: $small-text;
            font-weight: $bold;
            margin-bottom: 0;
            padding: 4px 16px 12px 16px;
            text-transform: initial;
        }

        &__single-value--row{
            display: flex;
            align-items: center;
            gap: 0 10px;

            .lang-flag{
                display: block;
                border-radius: 2px;
            }

            .iti-flag.auto{
                display: none;
            }

            .iti-flag.ja,
            .iti-flag.jp {
                height: 14px;
                background-position: -2611px 0px;
            }
            .iti-flag.ko,
            .iti-flag.kr {
                height: 14px;
                background-position: -2787px 0px;
            }
            .iti-flag.ua,
            .iti-flag.uk {
                height: 14px;
                background-position: -5175px 0px;
            }
            .iti-flag.en,
            .iti-flag.gb {
                height: 10px;
                background-position: -1775px 0px;
            }
            .iti-flag.he,
            .iti-flag.il {
                height: 15px;
                background-position: -2369px 0px;
            }
            .iti-flag.zh,
            .iti-flag.zh-cn,
            .iti-flag.cn {
                height: 14px;
                background-position: -1049px 0px;
            }
            .iti-flag.zh-tw {
                height: 14px;
                background-position: -5131px 0px;
            }
            .iti-flag.nb,
            .iti-flag.no {
                height: 15px;
                background-position: -3774px 0px;
            }
            .iti-flag.da,
            .iti-flag.dk {
                height: 15px;
                background-position: -1335px 0px;
            }
        }
    }
    .css-4avucx-control,
    .css-3wv91y-control{
        border-color: $border-color;
        background-color: #fff;
        border-radius: 4px;
        border-width: 1px;
    }
    .css-jtaxry-option:active {
        -webkit-tap-highlight-color: transparent;
    }
    .css-jtaxry-option:active {
        background-color: $secondary-brand-color;
        -webkit-tap-highlight-color: transparent;
    }
}

.Select-menu-outer {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
    border: none;
    border-top-color: unset;
    box-sizing: border-box;
}

.Select-option{
    font-family: $mainFont;
    cursor: pointer;
    background: #fff;
    padding: 8px 16px;
    color: $color-dark;
    font-size: 14px;
    font-weight: normal;

    &.is-focused{
        background: rgba($secondary-brand-color, 0.5);
        color: $color-dark;
        padding: 8px 16px;
    }

    &.is-selected{
        background: $color-blue;
        color: #fff;
    }
}


//organization page -- modal
.modal__edit-role,
.modal__add-team-member{
    overflow: visible;
    .modal-body{
        min-height: 150px;
    }

    .modal-body--scroll-box{
        overflow: visible;
    }


    .react-select__option{
        .user__item {
            padding-top: 0px;
            padding-bottom: 0px;
        }


        .user__avatar {
            width: 20px;
            height: 20px;
        }
    }

    .single-select,
    .Select--single{
        //position: static;

        //.react-select__menu{
        //    top: 0;
        //}
    }

    .Select-menu-outer {
        top: 56px;
        border-radius: $br;
        box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
        border: none;
        border-top-color: unset;
        box-sizing: border-box;
    }

    .one-row__edit-role{
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .txt{
            margin-right: 10px;
        }

        img{
            display: none;
        }
    }
}

