// import font
@import "ui-kit--sm-meet/font-utils";
@import "reset-css";

$font-path: '../fonts/';
//@include font-face("Mulish", "#{$font-path}mulish/Mulish-Medium",  500, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold",    600, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-ExtraBold", 900, inherit, eot woff2 woff ttf otf svg);

@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Regular",      400, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Light",        300, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-SemiBold", 500, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Bold",     600, inherit, eot woff2 woff ttf otf svg);

@import "ui-kit--sm-meet/font-awesome-6/fontawesome"; // v6.6.0

@import "ui-kit--sm-meet/vars";
@import "inc/toggle-switch";
@import "ui-kit--sm-meet/ui-kit";
@import "components/alerts";
@import "components/btn-bar";
@import "components/react-select";
@import "components/live-help--float-button";
@import "components/strong-password-component";

@import "inc/bootstrap-imports/bootstrap-imports";
@import "pages/page--register-new-account";
//@import "components/quotes";


*{
    box-sizing: border-box;

    &:before, &:after{
        box-sizing: border-box;
    }
}


:root{
    --index: calc(1vw + 1vh);
}

.show-on-mob{
    @include break-min($mob){
        display: none;
    }
}
.hide-on-mob{
    display: none;
    @include break-min($mob){
        display: block;
    }
}

.divider {
    &.divider-orientation-horizontal {
        margin-block: 12px;
        height: 1px;
    }

    &.divider-orientation-vertical {
        margin-inline: 12px;
        width: 1px;
    }

    &.divider-color-secondary {
        background-color: $border-color;
    }
}

.toggle-switch__helper{
    left: 0;
}

.registration-step {
    display: none;
    &.active {
        display: block;
    }
}

.title{
    font-weight: bolder;

    &--h1,
    &--h2{
        font-size: 30px;
        line-height: 1.1;
    }
}

.authorization-page{
    background: rgba($secondary-brand-color, 0.3);
}


// dark mode
.dark-mode{
    .authorization-page{
        background: $body-bg--dark;
    }

    .rc{
        &--login-header{
            picture{
                svg{
                    @include makeGrey;
                }
            }
        }

        &--or-login{
            &:before, &:after{background: $color-border--dark;}
        }

        &--form{
            border-color: $color-border--dark;
            background: $sidebar-bg--dark;
            @include shadowDarkMode;
        }
    }

    .login-form--social-links{
        .login-form--links-item {
            .login-form--social-icon{
                //background: $color-disabled-text--dark;
            }
            .social-links--txt{
                color: $color-primary--dark;
            }

            &:hover{
                .social-links--txt{
                    color: $color-primary;
                }
            }
        }
    }

    .main-container{
        //reset pass page
        &__form--reset-pass{
            background: $body-bg--dark;

            .phantom-popup{
                background: $second-brand-color--dark;
                box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);


                .close{
                    &:before, &:after{background: $color-danger--dark;}
                }

                &.card-form__set-new-pass{
                    .form-group{
                        ul{
                            li{color: $color-danger--dark;}
                        }
                    }
                }
            }
        }

        &__ga{
            .ga{
                &__qr-code{
                    background: darken($accent-bg, 6%);
                }
            }
        }
    }
}

.rc{
    &--login-header{
        width: 100%;
    }

    &--or-login{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-bottom: 24px;

        &:before, &:after{
            display: block;
            content: '';
            flex: 1;
            height: 1px;
            background: $border-color;
            max-width: 166px;
        }
    }

    &--login{
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        min-height: 100dvh;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        padding: 24px 20px;
    }

    &--footer{
        width: 100%;
        text-align: center;
    }

    &--login-form{
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;

        .title--h2{
            text-align: left;
            padding-left: 24px;
        }
    }

    &--form{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0;
        padding: 24px;
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid $border-color;
        box-shadow: 2px 2px 15px 0px rgba(45, 51, 72, 0.10);
        border-radius: 8px;
        background: $white;

        .form-group__checkbox{
            white-space: nowrap;
        }

        .mb-xxl{ margin-bottom: 24px !important; }
    }

    &--form__body{
        width: 100%;

        .btn-bar{
            padding-top: 32px;
            margin-bottom: 16px;
            text-align: center;

            .btn{
                width: 100%;
                min-height: 42px;
                font-family: $titleFont;
                font-size: $main-fs;
            }
        }
    }
}

.login-form--social-links{
    padding-top: 24px;
    text-align: center;
    width: 100%;
    margin: 0 auto 24px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px 32px;

    .login-form--links-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        min-height: 60px;
        transition: all 0.22s ease-out;

        .social-links--txt{
            width: 100%;
            font-size: $main-text;
            color: $color-dark;
        }

        &.login-form--links-g{

        }
        &.login-form--links-fc{
            color: #3F5D92;
        }
        &.login-form--links-x{
            color: #000;
        }
        &.login-form--links-d{

        }
    }
    .login-form--social-icon{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary-brand-color;

        svg{
            height: 22px;
        }
    }
}

.main-container{
    //reset pass page
    &__form--reset-pass{
        justify-content: center;
        background: $secondary-brand-color;

        .phantom-popup{
            position: relative;
            background: #fff;
            padding: 55px 30px 30px;
            box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
            @include break-max($mob){
                position: absolute;
                max-width: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                border-radius: 0;
                padding-left: 22px;
                padding-right: 22px;
            }
            @include break-min($mob){
                max-width: 480px;
                border-radius: 20px;
            }

            .close{
                position: absolute;
                top: 12px;
                right: 12px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
                text-decoration: none;
                &:before, &:after{
                    position: relative;
                    content: '';
                    display: block;
                    height: 20px;
                    width: 2px;
                    background: $color-red;
                    border-radius: 2px;
                }
                &:before{
                    transform: rotate(-45deg);
                }
                &:after{
                    transform: rotate(45deg);
                    left: -2px;
                }
            }
            .title--h3{
                font-family: $titleFont;
                font-weight: 600;
            }
            .btn-bar{
                padding-top: 15px;
                margin-bottom: 25px;
                text-align: center;

                .btn{
                    height: 45px;
                    font-size: 16px;
                }
            }
            .p{
                text-align: center;
            }

            &.card-form__email-sended{
                border-radius: 20px;
                padding: 46px 24px;
                .card-form__header{
                    margin-bottom: 5px;

                    &-picture{
                        display: block;
                        margin-bottom: 12px;
                        text-align: center;
                    }

                    .title{
                        font-size: 16px;
                    }
                }

                .p{
                    max-width: 340px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &.card-form__set-new-pass{
                padding-top: 30px;
                .form-group{
                    ul{
                        li{
                            font-size: 12px;
                            font-style: italic;
                            color: $color-red;
                        }
                    }
                }
            }
        }
    }

    &__ga{
        .card-form{
            picture{
                display: block;
                text-align: center;
            }
            .p{
                font-size: 16px;
            }
            .btn-bar{
                display: flex;
                gap: 5px 8px;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                text-align: center;
                .btn{

                }

                .link{
                    margin: 5px;
                    display: inline-block;
                    padding: 5px;
                }
            }
        }
        .ga{
            &__logo{
                margin-bottom: 16px;
                img{
                    height: 82px;
                }
            }
            &__qr-code{
                padding: 14px;
                background: $accent-bg;
                border-radius: $br;
                margin-bottom: 14px;

                img{
                    height: 100px;
                }
            }
        }
    }
}


.toggle-switch__helper:after {
    top: 2px;
    left: 3px;
}