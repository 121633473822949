@mixin placeholder-style{
  font-size: $main-text;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  color: $color-neutral;
}

@mixin placeholder-style__dark{
  font-size: $main-text;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  color: $color-placeholder--dark;
}

::-webkit-input-placeholder {
  @include placeholder-style;
}
::-moz-placeholder {
  @include placeholder-style;
}
:-ms-input-placeholder {
  @include placeholder-style;
}
input:-moz-placeholder {
  @include placeholder-style;
}
input::placeholder{
  @include placeholder-style;
}

.form-group,
.field-container{
  label,
  .label,
  .card-block__title{
    display: flex;
    align-items: baseline;
    gap: 6px;
    font-size: $main-text;
    color: $color-neutral-dark;
    margin-bottom: 0;

    &--radio-title{
      color: $color-neutral-dark;
      display: flex;
      align-items: baseline;
      gap: 12px 5px;
      padding: 0;
      margin: 0;
      font-weight: $normal;
    }

    .required{color: $color-danger;}

    .label-title--secondary{
      font-size: $small-text;
      color: $color-neutral;
    }
  }
}

.form-group {
  margin-bottom: 16px;

  &.mb-0{margin-bottom: 0;}

  label{
    margin-bottom: 0;
  }

  label,
  .label,
  .card-block__title{
    margin-bottom: 6px;
  }

  &__label-wrapper{
    display: flex;
    align-items: baseline;
    gap: 4px 8px;
  }

  &.custom-checkbox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    label{
      padding-left: 0;
      cursor: pointer;
			margin: 0;

			.card-block__title{margin: 0}
    }
  }

  &.--error{
    .form-group .form-control,
    .form-control{
      border-color: $color-danger;
    }

    .reschedule-event--time-phantom-toggler,
    .upload-file--preview,
    .custom-control-indicator {
        border-color: $color-danger !important;
    }
  }

  .required-icon{
    color: $color-danger--dark;
    font-size: $main-text;
  }
}

.txt--error,
.harmonizely-error-basic{
  padding-top: 2px;
  color: $color-danger;
  font-style: italic;
  font-size: $small-text;
}

.card-block__title label{
  display: flex;
  align-items: baseline;
  gap: 6px;
  font-size: $main-text;
  color: $color-neutral-dark;
  padding-left: 0;
}

.card-block__title,
.title{
  .ico--hint,
  .zmdi-info-outline {
    color: $color-icons;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: bold;
    margin-left: 3px;
    font-size: $main-text;

    &:before {
      content: '\f30f';
    }
  }
}

.form-group--range{
  .card-block__title{
    margin: 0;
    padding: 0;
  }
}

#react-select-2-listbox {
  z-index: 9999;
}

.field-container{
  display: flex;
  justify-content: flex-start;

  &.grid-x{
    &-gap-4 {column-gap: 4px;}
    &-gap-8 {column-gap: 8px;}
    &-gap-16{column-gap: 16px;}
    &-gap-24{column-gap: 24px;}
  }

  &.grid-y{
    &-gap-4 {row-gap: 4px;}
    &-gap-8 {row-gap: 8px;}
    &-gap-16{row-gap: 16px;}
    &-gap-24{row-gap: 24px;}
  }

  &.align{
    &--baseline{align-items: baseline;}
    &--bottom{align-items: flex-end;}
    &--center{align-items: center}
    &--start{align-items: start}
  }

  &.justify{
    &--start{justify-content: start;}
    &--center{justify-content: center;}
    &--end{justify-content: end}
  }

  &.direction{
    &--row{flex-direction: row;}
    &--row-reverse{flex-direction: row-reverse;}
    &--column{flex-direction: column; align-items: stretch;}

    // breakpoint
    &--row{
      &.to-col-on-mob_xs{
        @include break-max(370px){
          flex-direction: column;
          align-items: stretch;
        }
      }
      &.to-col-on-mob_m{
        @include break-max(560px){
          flex-direction: column;
          align-items: stretch;
        }
      }
      &.to-col-on-mob_xl{
        @include break-max(480px){
          flex-direction: column;
          align-items: stretch;
        }
      }
      &.to-col-on-mob{
        @include break-max($mob){
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }

  &.allow-wrap{
    flex-wrap: wrap;
  }

  .form-group{
    margin: 0;
    padding: 0;
  }

  .field-group,
  .customer-select-container,
  .form-group{
    flex: 1;
  }

  .btn-bar{
    width: auto;
    flex: unset;
  }

  &_title{
    display: block;
    margin-bottom: 8px;
  }
}

.fieldset{
  border: none;
  margin: 0;
  padding: 0;

  &--form{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-group{
      margin: 0;
      padding: 0;
    }
  }
}

.form-control {
  .select-category-prefix__control {
    border: 1px solid $border-color;
    border-color: $border-color;
    color: $color-dark;
    border-radius: 4px;
    font-family: $mainFont;

    &:focus, &:hover {
      border: 1px solid $color-dark;
      border-color: $color-dark;
    }

  }
  .select-category-prefix__placeholder {
    color: darkgray;
  }
  .select-category-prefix__dropdown-indicator,
  .select-category-prefix__dropdown-indicator:hover {
    color: $color-dark;
  }
  .select-category-prefix__option {
    text-transform: capitalize;
  }
}

.react-datepicker__input-container input[type=text],
.react-multi-email input[type=text],
select,
select.form-control,
.form-control--input,
input[type=text].form-control,
input[type=tel].form-control,
input[type=number].form-control,
input[type=email].form-control,
input[type=password].form-control,
textarea.form-control{
  width: 100%;
  border: 1px solid $border-color;
  color: $color-dark;
  padding: 0 18px;
  font-size: $main-text !important;
  line-height: 1;
  margin: 0;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $mainFont;

  &:disabled{
    color: $color-grey;
  }
  &:focus {
    border: 1px solid $color-dark;
  }

  &.--error{
    border-color: $color-danger;
  }
}

.react-select__input-container{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

input[type=text]:read-only {
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $color-grey;
  background: $lite-gray;
}

.invite-link__wrapper{
  input[type=text]:read-only {
    font-size: $main-text !important;
    border-radius: 25px;
    padding-left: 16px;
    padding-right: 16px;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: $color-dark !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset!important;
}

textarea.form-control{
  height: 100px;
  padding: 12px 18px;
  line-height: 1.4;
}

select.form-control:not([size]):not([multiple]),
.input-group-addon {
  height: 36px;
}

.input-group-addon{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $secondary-brand-color;
  background: $secondary-brand-color;
}

.form-control-input--select,
select.form-control,
select.select, select{
  padding-right: 50px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url("../../../public/img/simplymeet_theme/select--arrow.svg");
  background: transparent url("../../../public/img/simplymeet_theme/select--arrow.svg") no-repeat !important;
  background-position: right 9px center !important;
  background-size: 17px !important;
}

body {
  .css-1y43m4m-indicatorContainer {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .react-select__clear-indicator {
    svg {
      display: none;
    }

    height: 36px;
    width: 36px;
    background: url("../../img/icon__close.svg") no-repeat center;
    background-size: 12px;
  }

  .react-select__value-container,
  .css-1gu4dwr-ValueContainer {
    padding-left: 18px;
  }

  .react-select__multi-value {
    background-color: $secondary-brand-color;
    background: $secondary-brand-color;
    display: inline-flex;
    align-items: center;
    gap: 0px;
    border-radius: 4px;

    &:hover{
      .react-select__multi-value__remove{
        background: transparent;
        color: $color-red;
      }
    }
  }

  .react-select__multi-value__label{
    padding-left: 12px;
    padding-right: 0;
  }

  .react-select__multi-value__remove{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    min-width: 28px;
    height: 25px;
    padding: 2px;
    line-height: 1;
    border-radius: 4px;
    overflow: hidden;
    background: $secondary-brand-color;
    color: $color-icons;

    svg{
      height: 14px;
      width: 15px;
    }
  }

  .react-select__dropdown-indicator {
    padding: 5px 6px;
  }

  .--error{
    .react-select__control,
    .css-1liafey-control {
      border-color: $color-danger;
    }
  }

  .react-select__control,
  .css-1liafey-control {
    background-color: $white;
    border-color: $border-color;
    border-radius: 4px;
    border-width: 1px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 36px;
    outline: 0 !important;
    position: relative;
    padding: 0;
  }

  .react-select__control{
    &:hover{
      border-color: $border-color;
      background: #fff;
    }
  }

  .react-select{
    &__control--is-focused{
      border: 1px solid $color-dark;
      border-color: $color-dark;

      &:hover, &:focus{
        border-color: $color-dark;
        box-shadow: none;
      }
    }
    &__indicators{

    }
    &__indicator-separator{
      opacity: 0;
    }
    &__dropdown-indicator{
      color: $color-dark;
      background-image: url("../../../public/img/simplymeet_theme/select--arrow.svg");
      background: transparent url("../../../public/img/simplymeet_theme/select--arrow.svg") no-repeat !important;
      background-position: right 9px center !important;
      background-size: 17px !important;
      svg{
        opacity: 0;
      }
    }

    &__placeholder{
      @include placeholder-style;
    }
  }

  .company-settings--block{
    .react-select{
      &__dropdown-indicator{
        padding-right: 10px;
        color: $color-dark;
      }
    }
  }

  .form-group__select{
    .react-select__option{
      .form-group{
        margin: 0;
        padding: 0;
      }
    }

    &._withCheckboxInDropdownMenu{
      .extra-items{
        order: 100;
        display: inline-block;
        white-space: nowrap;
        color: $color-primary;
        font-size: $main-text;
        line-height: 1;
        margin-left: auto;
        margin-right: 0;
      }

      .react-select{
        &__value-container{
          @include break-min($mob){
            flex-wrap: nowrap;
          }
        }

        &__multi-value{
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:before{
            position: relative;
            left: 8px;
            content: '';
            display: inline-block;
            width: 8px;
            min-width: 8px;
            height: 8px;
            border-radius: 25px;
            margin-right: 4px;
            background: $color-primary--dark;
          }

          &__label{
            max-width: 75px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

//____________________________________________________________________
// TODO fix this code, do some good component
// Custom checkbox from simplybook
.checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px !important;
    height: 24px !important;
    opacity: 0;
    z-index: 2;
  }

  &__box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    border-radius: 4px;
    border: 1px solid $border-color;
    color: $color-neutral-dark;
    background-color: $color-secondary;
    position: relative;
    transition: all .25s ease;

    &:before, &:after{
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 6 Pro';
      font-weight: 300;
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 0;
      transition: all .25s ease;
    }

    &:before{
      content: '\f00c';
    }

    &:after{
      content: '\f068';
    }
  }

  input:focus + .checkbox__box,
  input:hover + .checkbox__box{
    border: 1px solid darken($border-color, 10%);
    background-color: lighten($color-secondary, 10%);
  }

  input:checked + .checkbox__box {
    &:before {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  input:checked + .semi-checked.checkbox__box{
    &:after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    &:before {
      content: "";
    }
  }
}
//____________________________________________________________________

.form-group__checkbox{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 3px;

  .label{
    align-items: flex-start;
    gap: 8px
  }

  .form-checkbox-line{
    .form-group__label{padding-left: 0;}
  }

  .toggle-switch{
    top: -3px;
    order: -1;
    margin-right: 10px;
  }

  .custom-checkbox{
    font-family: $font-family-icon;
    position: relative;
    top: -2px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin: 0;
    border-radius: $br;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      background: transparent;
      content: '\f00c';
      font-family: $font-family-icon;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      font-size: 16px;
      text-align: center;
      font-weight: 300;
      transform: scale(0.5);
      transition: all 0.2s ease-out;
    }
  }

  .card-block__title{
    padding: 0;
    margin: 0;
    font-size: $main-text;
    color: $color-dark;

    &.--fz-main{font-size: $main-text;}
  }

  &.--checkbox{
    display: block;

    .form-checkbox-line{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2px 8px;

      .form-check-label{
        margin: 0;
        padding: 0;
      }

      .checkbox{
        position: absolute;
        top: 0;
        left: 0;
        width: 26px;
        height: 26px;
        opacity: 0;
        z-index: 999;
      }

      .custom-checkbox{order: -10;}

      input[type=checkbox]:checked + .custom-checkbox{
        &:before {
          opacity: 1;
          transform: scale(1);
          transition: all 0.2s ease-out;
        }
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  &:not(.switch-container){
    input[type="checkbox"]:checked + .label{
      .custom-checkbox {
        background: $secondary-brand-color;

        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.form-group--color-picker{
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 4px;

  input:disabled{
    display: none;
  }

  &__preview{
    position: relative;
    top: -5px;
    margin-right: 8px;
    padding: 5px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgb(231, 236, 247);
    display: inline-block;
    cursor: pointer;
    > div{
      width: 36px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid $border-color;
    }

    .icon{
      display: none;

      svg{
        height: 14px;
      }
    }
  }

  .card-block__title{
    //font-size: 16px;
    //color: $color-dark;
    padding-left: 0;
  }
}

.form-group--duration-field {
  .card-block__title {
    //font-size: 16px;
    //color: $color-dark;
    padding-left: 0;
  }
}

.form-group--slug-field{
  .card-block__title{
    //font-size: 16px;
    //color: $color-dark;
    padding-left: 0;
  }
  .input-group{
    width: auto;
    flex: 1;
    flex-wrap: wrap;
    align-items: stretch;

    .input-group-addon{
      flex-direction: row;
      justify-content: flex-start;
      display: inline-flex;
      align-items: center;
      text-align: left;
      white-space: normal;
      word-break: break-all;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      min-height: 36px;
      height: auto;
      flex: 1;
      width: auto;
    }

    .form-group{
      flex: 1;

      .form-control{
        flex: 1;
        height: 100%;
        white-space: nowrap;
        word-break: break-all;
        min-width: 120px;

      }
    }
  }
}

.form-group__upload-file{
  .form-group__label-wrapper{
    .label{
      padding-left: 0;
      color: $color-neutral-dark;
    }
  }
  .upload-file{
    &--component{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px 20px;
      flex-wrap: wrap;
    }

    &--preview{
      position: relative;
      width: 100%;
      max-width: 184px;
      min-width: 184px;
      min-height: 184px;
      height: 184px;
      padding: 2px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: $color-secondary-50;
      border: 1px dashed transparent;
      border-radius: 5px;
      text-align: center;

      > label{
        position: absolute;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        opacity: 1;
        justify-content: center;
      }

      //.btn{
      //  position: absolute;
      //  top: 46%;
      //  left: 100%;
      //  transform: translate(20px, 40px);
      //  white-space: nowrap;
      //  display: none;
      //}

      &.--empty{
        border-color: $color-primary;
      }
      &.--uploaded{
        border-color: $color-primary;
        background: rgba(#fff, 0.5);
        padding: 0;

        .upload-file--cta-txt{
          opacity: 0;
        }

        .btn{
          display: inline-flex;
        }
      }
    }

    &--upload-action{
      display: none;
    }

    &--preview.--uploaded + .upload-file--btn-bar{
      .upload-file--upload-action{
        display: inline-flex;
      }
    }

    &--cta-txt{
      color: $color-neutral-dark;
      font-size: 12px;
      font-style: italic;
    }

    &--image{
      object-fit: contain;
      height: auto;
      max-height: 80px;
      max-width: 100%;
    }

    &--rules{
      padding-top: 8px;
      line-height: 1.2;
      color: $color-neutral;
      font-size: 13px;
    }

    &--btn-bar{
      .btn-bar__row{
        flex-direction: column;
        align-items: baseline;
        gap: 10px;

        .btn{
          min-width: 100px;
        }
      }
    }
  }

  &.round{
    .upload-file{
      &--preview{
        max-width: 180px;
        min-width: 180px;
        min-height: 180px;
        height: 180px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }

      &--image{
        object-fit: cover;
        height: 180px;
        max-height: 180px;
        max-width: 100%;
      }
    }
  }

  &.rectangle{
    .upload-file{
      &--component{
        //flex-direction: column;
        //align-items: flex-start;
        //gap: 10px;
      }
      &--preview{
        max-width: 320px;
        min-width: 180px;
        min-height: 180px;
        height: 180px;
        overflow: hidden;
        object-fit: cover;
      }

      &--image{
        object-fit: cover;
        height: 180px;
        max-height: 180px;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &.rectangle-logo{
    .upload-file{
      &--component{
        //flex-direction: column;
        //align-items: flex-start;
        //gap: 10px;
      }
      &--preview{
        max-width: 320px;
        min-width: 180px;
        min-height: 180px;
        height: 180px;
        overflow: hidden;
        object-fit: cover;
      }

      &--image{
        object-fit: contain;
        height: 180px;
        max-height: 180px;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.form-group__checkbox.switch-container{
  padding: 0;
  flex-direction: row;
  flex-wrap: nowrap;

  .switch-txt{
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 4px 8px;
  }

  .toggle-switch {
    top: 1px;
    min-height: unset;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: unset;
  }
  .switch-description{
    color: $color-grey;
    font-size: $main-text;
  }

  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    right: 0px;
    left: 0;
  }

  .ant-switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    line-height: 26px;
    background-color: #fff;
    border: 1px solid $border-color;

    &:focus{
      box-shadow: none;
    }

    &.ant-switch-checked {
      background-color: #fff;

      .ant-switch-handle{
        &:before{
          background-color: $color-blue;
        }
      }
    }
  }

  .ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 20px;
    height: 20px;

    &:before{
      border-radius: 50%;
      box-shadow: none;
      background-color: #D5E0F0;
    }
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px);
  }
}

.ant-radio-group{
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 16px;

  &.view-row{
    flex-direction: row;
  }

  &.view-column{
    flex-direction: column;
  }

  .ant-radio-wrapper{
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    align-items: flex-start;
    padding-left: 0;
    margin: 0 !important;
    gap: 6px;

    .ant-radio{
      margin-right: 2px;
      top: 0px;
    }

    .ant-radio + span{
      padding: 0;
    }
  }

  .ant-radio-inner{
    width: 22px;
    height: 22px;
    background-color: #fff;
    border: 1px solid $border-color;

    &:after{
      height: 30px;
      width: 30px;
      top: 3px;
      left: 3px;
      background: $color-blue;
      border-radius: 50%;
    }
  }

  .ant-radio-checked .ant-radio-inner{
    border-color: $border-color;
    background-color: $border-color;
  }

  .ant-radio-checked::after {
    display: none !important;
    top: -1px !important;
    left: -1px !important;
  }
}

span.ant-radio + * {
  padding-right: 0;
  padding-left: 0;
}

.ant-picker{
  border: 1px solid $border-color;
  color: $color-dark;
  padding: 5px 12px 5px 18px;
  font-size: 15px;
  line-height: 1;
  margin: 0;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;

  .ant-picker-range{

  }
}
.ant-picker-clear {
  height: 26px;
  width: 26px;
  padding: 0;
  margin: 0;
  background: #fff;
  .anticon-close-circle{
    height: 26px;
    width: 26px;
    background: url("../../img/icon__close.svg") no-repeat center;
    background-size: 12px;

    svg{
      display: none;
    }
  }
}


.form-group--template{
  .ql-toolbar.ql-snow {
    border: 1px solid $border-color;
    border-bottom: none;
    border-radius: $br $br 0 0;
    padding: 8px;
    background: $color-secondary-50;
  }
  .ql-container.ql-snow {
    border: 1px solid $border-color;
    border-radius: 0 0 $br $br;
    min-height: 70px;
    background: #fff;
  }
}


.custom-checkbox{
  .card-block__title {
    padding-left: 36px;
    color: $color-dark;
    font-size: $main-text;
  }
}
.custom-control-input:checked ~ .custom-control-indicator {
  border-color: $border-color;
  color: $color-dark;
  background: darken($secondary-brand-color, 2%);
}

.intl-tel-input{
  width: 100%;
}

.ant-radio-wrapper{
  color: $color-dark;
  font-weight: normal;
}

.form-group{
  ul{
    padding-top: 5px;
    li{
      color: $color-red;
      font-style: italic;
      font-size: 13px;
    }
  }
  &.has-danger {
    .form-control-label {
      color: $color-red;
      font-style: italic;
      font-size: 13px;
    }
  }
}

.increasing-field{
  &--wrapper{
    display: flex;
    justify-content: center;
    position: relative;
  }
  &--field{
    flex: 1;

    .form-control{
      padding-right: 46px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        opacity: 0;
        display: none;
      }
      &::-webkit-inner-spin-button {
        display: none;
        opacity: 0;
      }
    }
  }
  &--action{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    max-width: 40px;
  }
  &--btn{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 40px;
    cursor: pointer;
    border-radius: 4px;

    svg{
      height: 14px;
    }
    &:hover{
      background: $secondary-brand-color;
    }
  }
}

.form-group--flexible-duration-field{
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 20px;

  .form-group--select{
    flex: 1;
    margin: 0;

    @include break-min($small-lap){
      flex: unset;
    }
  }

  .btn-bar{
    margin-top: auto;
  }
}

.ant-radio-group{
  .ant-radio-input{
    top: 5px;
    right: 5px;
    left: auto;
  }
}
.ant-radio-checked::after {
  position: absolute;
  top: -1px;
  left: -1px;
}

.radio-item--list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px 20px;
  flex-wrap: wrap;

  .radio-item{
    &--label{
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
    }
    .radio{
      position: absolute;
      opacity: 0;
    }
    .custom-radio{
      position: relative;
      width: 26px;
      height: 26px;
      min-width: 26px;
      border-radius: 50%;
      border: 1px solid $border-color;
      line-height: 1;
      margin: 0;
      padding: 0;
      background: #fff;

      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        line-height: 1;
        background: $color-marketing;
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.2s ease;
      }
    }
    .radio:checked + .custom-radio{
      background: $color-secondary-50;
      &:before{
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

// Time dropdown selector
.form-group--input-time .field-container{position: relative;}
.form-group--input-time .input-time-dropdown {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  max-width: 100%;
  min-width: 250px;
  max-height: 220px;
  padding: 8px 20px 0 8px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
  background: $color-white;
  z-index: 1;
  @include scroll-style(5px);

  &.bottom{
    top: calc(100% + 2px);
    right: 0;
  }
  &.bottom_right{
    top: calc(100% + 2px);
    left: auto;
    right: 0;
  }
  &.top{
    top: auto;
    bottom: calc(100% + 3px);
  }
  &.top_right{
    top: auto;
    bottom: calc(100% + 3px);
    right: 0;
    left: auto;
  }

  &__section {
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    max-width: 70px;
    padding: 7px 0;
    text-align: center;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 4px;
    font-style: normal;
    font-size: $main-text;
    color: $color-neutral-dark;
    background: $color-time-slots;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      border-color: $color-primary;
    }

    &--selected {
      background: #B1DEF0;
    }
  }
}

.custom-date-field{
  flex-wrap: nowrap;

  .react-datepicker-wrapper{
    width: auto;
    .react-datepicker__input-container{
      i{
        border: 1px solid #EEEEEE;
        background-color: rgba(#fff, 0.3);
        min-width: 36px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}


.form-group--location-type{

}

.custom-radio{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 22px;
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 30px;
  border: 1px solid $border-color;
  margin: 0;
  padding: 0;

  &--input{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 22px;
    width: 22px;
    opacity: 0;
  }

  &--inner{
    flex: 1;
    height: 100%;
    border-radius: 30px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-out;

    &:before{
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px;
      background: rgba(#fff, 0.3);
      border-radius: 30px;
      transition: all 0.2s ease-out;
      transform: scale(0.5);
    }
  }

  input:checked + .custom-radio--inner{
    background: $border-color;
    &:before{
      background: $color-blue;
      transform: scale(1);
    }
  }
}

// custom fields
.field-group--input-group{
  &.form-group__meeting-name{
    margin-bottom: $mb-xl;
  }

  .form-group__meeting-name{
    width: 100%;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0;
    position: relative;
    border: 1px solid $border-color;
    border-radius: $br;
    box-sizing: border-box;
    line-height: 0.8;
    padding: 0;
    margin: 0;
    height: 36px;
    color: $color-neutral;

    .form-group--color-picker{
      flex: unset;

      .form-group--color-picker__preview{
        top: 0;
        margin: 0;
        display: flex !important;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: none !important;
        padding: 2px 10px !important;

        > div {
          width: 24px !important;
        }

        .icon{
          display: inline-flex;
        }
      }
    }

    .form-group:not(.form-group--color-picker){
      margin-top: -1px;
      margin-bottom: -1px;
      margin-right: -1px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .form-group__bar{
        display: none;
        position: absolute;
      }

      input[type=text]{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}


.dark-mode{
  ::-webkit-input-placeholder {
    @include placeholder-style__dark;
  }
  ::-moz-placeholder {
    @include placeholder-style__dark;
  }
  :-ms-input-placeholder {
    @include placeholder-style__dark;
  }
  input:-moz-placeholder {
    @include placeholder-style__dark;
  }
  input::placeholder{
    @include placeholder-style__dark;
  }

  .form-group,
  .field-container{
    label,
    .label,
    .card-block__title{
      color: $color-neutral--dark;

      &--radio-title{
        color: $color-neutral--dark;
      }
    }
  }


  .form-group {
    label{
      //margin-bottom: 0;
    }
    label,
    .label,
    .card-block__title{
      color: $color-second-text-color--dark;

      &--radio-title{color: $color-neutral--dark;}

      .required{color: $color-danger--dark;}
    }
  }

  .card-block__title label{color: $color-second-text-color--dark;}

  .card-block__title,
  .title{
    .ico--hint,
    .zmdi-info-outline {
      color: $color-icons;
    }
  }

  .form-control {
    .select-category-prefix__control {
      border-color: $color-border--dark;
      color: $color-neutral--dark;

      &:focus, &:hover {
        border-color: $color-border-focus--dark;
      }

    }
    .select-category-prefix__placeholder {
      color: $color-placeholder--dark;
    }
    .select-category-prefix__dropdown-indicator,
    .select-category-prefix__dropdown-indicator:hover {
      color: $color-neutral--dark;
    }
  }

  .react-datepicker__input-container input[type=text],
  .react-multi-email input[type=text],
  select,
  select.form-control,
  .form-control--input,
  input[type=text].form-control,
  input[type=tel].form-control,
  input[type=number].form-control,
  input[type=email].form-control,
  input[type=password].form-control,
  textarea.form-control{
    border-color: $color-border--dark;
    color: $color-neutral--dark;
    background-clip: padding-box;
    background-color: $input-bg--dark;

    &:disabled{
      color: $color-grey;
    }
    &:focus {
      border-color: $color-border-focus--dark;
      background-color: $input-bg--dark;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover {
    -webkit-text-fill-color: $color-neutral--dark !important;
    -webkit-box-shadow: 0 0 0 30px $input-bg--dark inset!important;
  }

  .input-group-addon{
    background: $second-brand-color--dark;
    border-color: $color-border--dark;
    color: $color-second-text-color--dark;
    cursor: pointer;

    &:hover{
      background: $input-bg--dark;
      color: $color-neutral--dark;
    }
  }

  .sbpay-field__field-select,
  .react-confirm-alert-body-element .sbpay-field__field-select, .sbpay-widget .sbpay-field__field-select,
  .form-control-input--select,
  select.form-control,
  select.select, select{
    background-image: url("../../../public/img/simplymeet_theme/select--arrow-dark-mode.svg");
    background: $input-bg--dark url("../../../public/img/simplymeet_theme/select--arrow-dark-mode.svg") no-repeat !important;
    background-position: right 9px center !important;
    background-size: 17px !important;
  }

  .react-select__input-container,
  .react-select__input,
  .react-select__single-value{
    color: $color-neutral--dark;
  }
  .react-select__multi-value__label{
    color: $color-neutral--dark;
  }

  .react-select__clear-indicator {
    background: url("../../img/icon__close.svg") no-repeat center;
    background-size: 12px;
  }

  .react-select__multi-value {
    background-color: $second-brand-color--dark;
    background: $second-brand-color--dark;

    &:hover{
      .react-select__multi-value__remove{
        background: $second-brand-color--dark;
        color: $color-danger--dark;
      }
    }
  }

  .react-select__multi-value__remove{
    background: $second-brand-color--dark;
    color: $color-placeholder--dark;
  }

  .react-select__control,
  .css-1liafey-control {
    background-color: $input-bg--dark;
    border-color: $color-border--dark;
  }

  .react-select__control{
    &:hover{
      border-color: $color-border--dark;
      background: $input-bg--dark;
    }
  }

  .react-select{
    &__control--is-focused{
      border-color: $color-border-focus--dark;

      &:hover, &:focus{
        border-color: $color-border-focus--dark;
      }
    }

    &__dropdown-indicator{
      color: $color-neutral--dark;
      background-image: url("../../../public/img/simplymeet_theme/select--arrow-dark-mode.svg");
      background: $input-bg--dark url("../../../public/img/simplymeet_theme/select--arrow-dark-mode.svg") no-repeat !important;
      background-position: right 9px center !important;
      background-size: 17px !important;
    }
  }

  .react-select__placeholder{
    color: $color-placeholder--dark;
  }

  .company-settings--block{
    .react-select{
      &__dropdown-indicator{
        color: $color-neutral--dark;
      }
    }
  }

  .checkbox {
    &__input {}

    &__box {
      border-color: $color-border--dark;
      color: $color-neutral--dark;
      background-color: $input-bg--dark;
    }

    input:focus + .checkbox__box,
    input:hover + .checkbox__box{
      border-color: $color-border-focus--dark;
      background-color: darken($input-bg--dark, 2%);
    }
  }

  .form-group__checkbox{
    .custom-checkbox{
      border-color: $color-border--dark;

      &:before {
        background: transparent;
      }
    }

    .card-block__title{
      color: $color-second-text-color--dark;
    }
  }

  .custom-control-indicator {
    border-color: $color-border--dark;
    color: $color-neutral--dark;

    &:hover{
      border-color: $color-border-focus--dark;
    }
  }
  .custom-control-input:checked ~ .custom-control-indicator{
    border-color: $color-border--dark;
    background: $input-bg--dark;
    color: $color-neutral--dark;
  }

  .form-group--color-picker{

    &__preview{
      background: $body-bg--dark;
      border-color: lighten($body-bg--dark, 4%);
      > div{
        border-color: $color-border--dark;
      }
    }

    .card-block__title{
      color: $color-neutral--dark;
    }
  }

  .form-group--duration-field {
    .card-block__title {
      color: $color-second-text-color--dark;
    }
  }

  .form-group--slug-field{
    .card-block__title{
      color: $color-neutral--dark;
    }
  }

  .form-group__upload-file{
    .form-group__label-wrapper{
      .label{
        color: $color-neutral--dark;
      }
    }
    .upload-file{
      &--preview{
        background: darken($second-brand-color--dark, 2%);
        border: 1px dashed transparent;

        &.--empty{
          border-color: $color-primary;
        }
        &.--uploaded{
          border-color: $color-primary;
          background: rgba(#fff, 0.1);
        }
      }

      &--cta-txt{
        color: $color-second-text-color--dark;
      }

      &--rules{
        color: $color-second-text-color--dark;
      }
    }
  }

  .form-group__checkbox.switch-container{
    .switch-description{
      color: $color-second-text-color--dark;
    }

    .ant-switch {
      background-color: $input-bg--dark;
      border-color: $color-border--dark;

      &.ant-switch-checked {
        background-color: $input-bg--dark;
        border-color: $alert-warning-border--dark;

        .ant-switch-handle{
          &:before{
            background-color: $color-blue;
          }
        }
      }
    }

    .ant-switch-handle {
      &:before{
        background: #bfbfbf;
      }
    }
  }

  .ant-radio-group{
    .ant-radio-inner{
      background-color: $input-bg--dark;
      border-color: $color-border--dark;

      &:after{
        background: $color-warning;
      }
    }
  }

  .ant-picker{
    border-color: $color-border--dark;
    color: $color-neutral--dark;
  }
  .ant-picker-clear {
    background: $input-bg--dark;
  }

  .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill{
    fill: $color-second-text-color--dark;
  }
  .ql-snow .ql-stroke{
    stroke: $color-second-text-color--dark;
  }
  .ql-snow .ql-picker{
    color: $color-second-text-color--dark;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    border-color: $color-border-focus--dark;
  }

  .ql-snow .ql-picker-options {
    background-color: $body-bg--dark;
    color: $color-second-text-color--dark;
    @include shadowDarkMode;

    .ql-picker-item{
      color: $color-second-text-color--dark;

      &:hover, &:focus, &:active{
        color: $color-primary--dark;
      }
    }
  }

  .form-group--template{
    .ql-toolbar.ql-snow {
      border-color: $color-border--dark;
      background: darken($sidebar-bg--dark, 5%);
    }
    .ql-container.ql-snow {
      border-color: $color-border--dark;
      background: $second-brand-color--dark;

      .ql-editor{
        *{
          color: inherit !important;
          background-color: transparent !important;
        }
      }
    }
  }


  .custom-checkbox{
    .card-block__title {
      color: $color-neutral--dark;
    }
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    border-color: $color-border--dark;
    color: $color-neutral--dark;
    background: $input-bg--dark;
  }

  .ant-radio-wrapper{
    color: $color-neutral--dark;
  }

  .form-group{
    ul{
      li{
        color: $color-danger--dark;
      }
    }
    &.has-danger {
      .form-control-label {
        color: $color-danger--dark;
      }
    }
  }

  .radio-item--list{
    .radio-item{
      .custom-radio{
        border-color: $color-border--dark;
        background: $input-bg--dark;

        &:before{
          background: $color-marketing;
        }
      }
      .radio:checked + .custom-radio{
        background: $input-bg--dark;
      }
    }
  }

  // Time dropdown selector
  .form-group--input-time .input-time-dropdown {
    @include shadowDarkMode;
    background: $body-bg--dark;
    @include scroll-style--dark(5px);

    &__item {
      color: $color-neutral-dark;
      background: #8eb0be;
      cursor: pointer;
      transition: all .25s ease;

      &:hover {
        border-color: $color-primary--dark;
        background: #6eb1cd;
        color: $color-neutral-dark;
      }

      &--selected {
        border-color: $alert-info-border--dark;
        background: $alert-info--dark;
        color: $color-second-text-color--dark;
      }
    }
  }

  .custom-date-field{
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        i{
          border: 1px solid #ccc;
          background-color: rgba(#fff, 0.1);
        }
      }
    }
  }

  .custom-radio{
    border-color: $color-border--dark;

    &--inner{
      &:before{
        background: rgba(#fff, 0.3);
      }
    }

    input:checked + .custom-radio--inner{
      background: $input-bg--dark;
      &:before{
        background: $color-warning--dark;
      }
    }
  }


  .ant-radio-group {
    color: $color-neutral--dark;
  }


  .ant-radio-wrapper {
    color: $color-neutral--dark;
  }

  .ant-radio {
    color: $color-neutral--dark;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $color-border-focus--dark;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
  .ant-radio-checked::after {
    border-color: $color-border--dark;
  }

  .ant-radio-inner::after {
    background-color: $color-warning--dark;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: transparent;
  }

  .intl-tel-input .country-list{
    li{
      .country-name{
        color: $color-neutral--dark;
      }
      .dial-code{
        color: $color-second-text-color--dark;
      }
    }
  }


  // custom fields
  .field-group--input-group{
    .form-group__meeting-name{
      border-color: $color-border--dark;
      color: $color-placeholder--dark;
    }
  }

}
