// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

// Utilities
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/breakpoints";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/hover";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/image";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/badge";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/resize";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/screen-reader";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/size";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/reset-text";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/text-emphasis";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/text-hide";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/text-truncate";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/transforms";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/visibility";

// Components
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/alert";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/cards";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/pagination";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/lists";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/list-group";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/nav-divider";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/forms";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/table-row";

// Skins
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/background-variant";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/border-radius";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/gradients";

// Layout
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/clearfix";
//@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/navbar-align";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/grid-framework";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/grid";
@import "../../../../public/vendors/bower_components/bootstrap/scss/mixins/float";
