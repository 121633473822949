

.dark-mode{
	.intl-tel-input .country-list {
		background-color: $body-bg--dark;
		border-color: $sidebar-bg--dark;
		@include shadowDarkMode;
		@include scroll-style--dark(5px);
		color: $color-second-text-color--dark;
	}
	.intl-tel-input .country-list .country.highlight{
		background-color: $sidebar-bg--dark;
	}

	.rc--header{
		a{
			picture{
				@include makeGrey;
			}
			svg{

			}
		}
	}

	.rc--new-account{
		color: $color-neutral--dark;

		.ico--hint{
			svg{
				path{
					fill: $color-icons;
				}
			}

			&:before{
				display: none;
			}

			&-txt_wrapper{
				color: $color-second-text-color--dark;
				background-color: $body-bg--dark;
				@include shadowDarkMode;
			}
		}

		.rc{
			//form
			&--col__steps{
				border-color: $color-border--dark;
				background: $sidebar-bg--dark;
				@include shadowDarkMode;

				.fieldset--form {
					.p--hint{
						color: $color-neutral;
					}

					.form-group .label{
						color: $color-second-text-color--dark;
					}

					.form-group--company-name{
						.field-container.grid-x-gap-8{
							.input-group-addon{
								border-color: $color-border--dark;
								background: $second-brand-color--dark;
								background-color: $second-brand-color--dark;
								color: $color-second-text-color--dark;
							}
						}
					}

					.form-group--phone-number{
						.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
							background: $second-brand-color--dark;

							.arrow{
								color: $color-icons;
							}
						}
					}

					.form-group__checkbox{
						.label{
							.custom-checkbox{
								border-color: $color-border--dark;
								background: $input-bg--dark;

								&:before{
									border-right: 2px solid $color-neutral--dark;
									border-bottom: 2px solid $color-neutral--dark;
								}
							}
						}
						input[type="checkbox"]:checked + .label{
							.custom-checkbox{
								border-color: $border-color--focus;
							}
						}
					}
				}
			}

			&--progress_line-wrapper{
				&:before, &:after{
					background: $body-bg--dark;
				}
			}

			&--progress_line{
				background: $input-bg--dark;

				&:before{
					background: $color-primary--dark;
				}
			}
		}

	}

	.rc--check-email .verify-email input[type="number"]{
		border-color: $color-border--dark;
		color: $color-neutral--dark;
		background-color: $input-bg--dark;
	}

	.main-container__form{
		.main-container__form-header{
			a{
				picture{
					@include makeGrey;
				}
				svg{

				}
			}
		}
	}

	.modal__rc--change-email{
		.modal{
			&-header__close{
				border-color: $color-border--dark;

				.btn-secondary-outline{
					svg{
						path{
							fill: $color-neutral--dark;
						}
					}
				}
			}
		}
	}
}

body{
	.rc--new-account{
		.react-select__value-container.react-select__value-container--has-value{
			padding-left: 4px;

			.extra-items{
				display: inline-block;
				white-space: nowrap;
				order: 10;
				margin-right: -6px;
				margin-left: auto;
				font-size: $main-text;
				color: $color-primary;
				line-height: 1;
			}
		}
	}
}

.rc--new-account{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 24px;
	font-size: $main-text;
	color: $color-neutral-dark;
	padding: 20px;
	min-height: 100vh;
	min-height: 100dvh;
	box-sizing: border-box;

	.title--h2{
		line-height: 1.4;
	}

	.date-format-select-field {
		.react-select {
			&__option {
				text-transform: none !important;
			}
		}
	}

	.column{
		width: 100%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}

	.ico--hint{
		position: relative;
		font-weight: bold;
		padding: 4px 5px;
		display: inline-block;
		cursor: pointer;

		svg{
			position: relative;
			height: 16px;
			top: 0.2em;

			path{
				fill: $color-icons;
			}
		}

		&:before{
			display: none;
		}

		&-txt_wrapper{
			position: absolute;
			right: -20px;
			top: 30px;
			padding: 10px;
			color: $color-dark;
			font-size: $small-text;
			font-weight: $normal;
			line-height: 1.28;
			text-align: left;
			background-color: #ffffff;
			box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
			border-radius: $br;
			box-sizing: border-box;
			width: 220px;
			min-width: 220px;
			opacity: 0;
			visibility: hidden;
			transition: all 0.35s ease-out;
			z-index: 10999;

			@include break-max($mob){
				position: fixed;
				top: auto;
				bottom: -30px;
				right: 0;
				left: 0;
				padding: 40px 20px 50px;
				max-width: 100%;
				width: 100%;
				border-radius: 0;
			}
		}

		&:focus, &:active, &:hover{
			.ico--hint-txt_wrapper{
				top: 22px;
				opacity: 1;
				visibility: visible;

				@include break-max($mob){
					top: auto;
					bottom: 0;
					right: 0;
					left: 0;
				}
			}
		}
	}

	.rc{
		&--header{
			width: 100%;
		}

		&--row{
			width: 100%;
			max-width: 500px;
			margin: 0 auto;

			&_header{
				padding: 0 24px;
			}

			.divider.divider-orientation-horizontal {
				margin-block: 0px;
			}
		}

		&--col__steps{
			width: 100%;
			padding: 24px;
			border-radius: 8px;
			background: $white;
			border: 1px solid $border-color;
			box-shadow: 2px 2px 15px 0px rgba(45, 51, 72, 0.10);

			.btn-bar--content-right .btn-bar__row{
				@include break-max(800px){
					justify-content: center;
					padding-top: 10px;

					.btn{
						width: 100%;
						max-width: 460px;
					}
				}
			}
		}

		&--footer{
			width: 100%;
			text-align: center;
		}

		//form
		&--col__steps{
			.fieldset--header{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 10px 24px;
			}

			.registration-step{

				i{line-height: 0.9;}

				.react-select__option-custom,
				.react-select__option{
					text-transform: capitalize;
				}

				.react-select__option-custom{
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 8px;

					&.select__option--is-selected{
						background: inherit;
						color: inherit;
					}

					.form-group__checkbox{
						margin: 0;
						padding: 0;
					}
				}

				.btn-bar{
					margin-bottom: 48px;

					.btn{
						width: 100%;
						min-height: 42px;
						font-family: $titleFont;
						font-size: $main-fs;
					}
				}
			}

			.fieldset--form {
				margin-bottom: 32px;

				.fields--grid{
					display: grid;
					grid-gap: 0px 20px;
					grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
					margin-bottom: -12px;
					//@include break-min(1200px){
					//	grid-template-columns: repeat(auto-fill, minmax(120px,1fr));
					//}
					.form-group{
						margin-bottom: 14px;
					}

					input{
						text-overflow: ellipsis;
					}
				}

				.p--hint{
					margin: 2px 0;
					border: none;
					padding-left: 0;
					font-size: $small-text;
					color: $color-neutral;
					font-style: normal;
				}

				.form-group .label{
					padding-left: 0;
					color: $color-neutral-dark;
				}

				.form-group--company-name{
					.field-container.grid-x-gap-8{
						position: relative;
						gap: 0;

						.input-group-addon{
							position: absolute;
							top: 1px;
							left: 1px;
							height: 34px;
							border-radius: 4px 0 0 4px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: $color-grey;
							border: none;
							border-right: none;
							white-space: nowrap;
							overflow: hidden;
							padding: 4px 8px 4px 14px;
							font-size: 14px;
							background-color: $secondary-brand-color;
							background: $secondary-brand-color;
						}
						.form-control{
							//border-left: none;
							//border-radius: 0 4px 4px 0;
							padding-left: 220px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

				.form-group--phone-number{
					.intl-tel-input.allow-dropdown .selected-flag {width: 60px;}
					.intl-tel-input.allow-dropdown input[type=tel] {padding-left: 66px;}
					.intl-tel-input .flag-container .arrow {pointer-events: none; font-size: 8px; margin-left: 10px;}

					.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
						position: relative;
						display: flex;
						align-items: center;
						padding-left: 12px;
						padding-right: 8px;
						justify-content: flex-start;
						gap: 5px;
						border-radius: 4px 0 0 4px;
						background: $color-secondary-50;
						min-width: 110px;
						width: 110px;

						&:before{
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							z-index: 2;
						}

						.selected-dial-code{
							padding: 0;
							display: inline-block;
						}
						.arrow{
							color: $color-icons;
							font-size: 12px;
							margin: 0 0 0 auto;
							padding: 0;
						}
					}
					.intl-tel-input.allow-dropdown.separate-dial-code input[type=tel] {
						padding-left: 120px;
					}
				}

				.card-form__footer{
					.form-group__checkbox{
						margin-bottom: 8px;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}

				//.form-group__checkbox{
				//	position: relative;
				//	input[type="checkbox"]{
				//		width: 30px;
				//		height: 30px;
				//		position: absolute;
				//		top: 0;
				//		left: 0;
				//		opacity: 0;
				//		z-index: 2;
				//	}
				//	.label{
				//		position: relative;
				//		display: flex;
				//		gap: 8px;
				//		align-items: flex-start;
				//
				//		.custom-checkbox{
				//			content: '';
				//			display: block;
				//			position: relative;
				//			top: -3px;
				//			width: 26px;
				//			min-width: 26px;
				//			height: 26px;
				//			border-radius: 4px;
				//			border: 1px solid $border-color;
				//
				//			&:before{
				//				position: absolute;
				//				top: 4px;
				//				left: 8px;
				//				content: '';
				//				width: 8px;
				//				height: 15px;
				//				border-right: 2px solid $border-color--focus;
				//				border-bottom: 2px solid $border-color--focus;
				//				transform: rotate(38deg);
				//				opacity: 0;
				//				transition: all 0.2s ease;
				//				border-radius: 1px;
				//			}
				//		}
				//	}
				//	input[type="checkbox"]:checked + .label{
				//		.custom-checkbox{
				//			border-color: $border-color--focus;
				//
				//			&:before{
				//				opacity: 1;
				//			}
				//		}
				//	}
				//}
			}
		}

		//Progress bar
		&--progress{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
			gap: 6px;
			margin-left: auto;
			margin-right: 0;

			&_line{
				position: relative;
				background: $secondary-brand-color;
				height: 4px;
				border-radius: 10px;

				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 0%;
					height: 100%;
					background: $color-primary;
				}

				&-wrapper{
					position: relative;
					width: 100%;
					min-width: 55px;
					height: 4px;
					border-radius: 10px;
					overflow: hidden;

					&:after,
					&:before{
						content: '';
						position: absolute;
						top: -1px;
						width: 2px;
						height: 6px;
						background: $white;
						transform: rotate(25deg);
						z-index: 2;
					}

					&:before{
						left: 50%;
					}

					&:after{
						left: 50%;
					}
				}
			}

			&_step-1{
				.rc{
					&--progress {
						&_line {
							&:before {
								width: 50%;
							}
						}
					}
				}
			}

			&_step-2{
				.rc{
					&--progress {
						&_line {
							&:before {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

}


.rc--check-email{
	.rc--header{
		text-align: left;
	}

	.column{
		width: 100%;
		max-width: 1200px;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	&__content{
		min-height: 100vh;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: space-between;
		text-align: center;
	}

	&__picture{
		display: block;
		text-align: center;
		margin-bottom: 20px;

		svg{
			max-height: 180px;

			@include break-max(480px) {
				max-height: 140px;
			}
		}
	}

	&__header{
		margin-bottom: 20px;
		.title{
			margin-bottom: 16px;
			@include break-max($mob){
				font-size: 24px;
			}
		}
	}

	.verify-email{
		input[type="number"] {
			width: 45px;
			min-width: 40px;
			height: 50px;
			min-height: 50px;
			line-height: 1;
			margin: 0;
			padding: 5px 0 4px;
			font-size: 26px;
			text-align: center;
			appearance: textfield;
			border: 1px solid $border-color;
			color: $color-neutral-dark;
			border-radius: 5px;
			outline: none;
			@include break-max(480px) {
				width: 40px;
			}

			&:focus,
			&:focus-visible {
				border: 1px solid $color-primary;
				box-shadow: none;
				outline: none;
			}
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* 2 group of 3 items */
		input[type="number"]:nth-child(n+4) {
			order: 2;
		}

		&--fieldset {
			border: 0;
			padding: 0;
			margin: 0;
		}
		&--label {
			border: 0 !important;
			clip: rect(1px, 1px, 1px, 1px) !important;
			-webkit-clip-path: inset(50%) !important;
			clip-path: inset(50%) !important;
			height: 1px !important;
			margin: -1px !important;
			overflow: hidden !important;
			padding: 0 !important;
			position: absolute !important;
			width: 1px !important;
			white-space: nowrap !important;
		}
		&--enter-code {
			display: flex;
			align-items: center;
			gap: 10px;
			justify-content: center;
			@include break-min(480px) {
				gap: 12px;
			}

			&:before {
				content: '';
				display: block;
				height: 1px;
				width: 6px;
				margin: 0;
				background: transparent;
				order: 1;
				@include break-min(480px) {
					width: 12px;
				}
			}
		}
		&--btn-bar {
			padding-top: 35px;
			text-align: center;

			.btn-bar--row {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px 10px;
			}

			.sb-btn {
				display: inline-flex;
				align-items: center;
				min-height: 48px;
				line-height: 1;
				border: none;
				font-size: 16px;
				font-weight: 500;
				padding: 6px 32px;
				margin: 0;
				cursor: pointer;
			}

			button:disabled,
			button[disabled] {
				border: 1px solid #DCDCDC;
				background-color: #DCDCDC;
				color: $color-neutral;
				cursor: not-allowed;

				&:hover{
					border: 1px solid #DCDCDC;
					background-color: #DCDCDC;
					color: #9DA8B3;
				}
			}
		}
		&--sub-btn-bar {
			padding-top: 18px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 10px;
		}
		&--enter-code-wrapper {
			display: flex;
			flex-direction: column;
			gap: 6px;
			max-width: 368px;
			margin: 0 auto;

			&.--error {
				input[type="number"] {
					border: 1px solid $color-red;
					color: $color-neutral-dark;
					background: #fff7f8;

					&:focus,
					&:focus-visible {
						border: 1px solid $color-blue;
						outline: none;
					}
				}

				.verify-email--enter-code + .verify-email--error {
					display: block;
				}
			}
		}
		&--resend-code {
			color: $color-neutral;

			.code-sent{
				display: none;

				&.active{
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					color: $color-neutral;
					font-size: 14px;
					animation: pulse-up infinite 1s ease;
					line-height: 1;
					padding: 8px 0;

					.icon{
						line-height: 1;
						svg{
							opacity: 0.6;
							height: 20px;
						}
					}

					.sec{
						color: $color-danger;
					}
				}
			}
		}
		&--error{
			text-align: right;
			line-height: 1;
			font-size: 12px;
			font-style: italic;
			color: $color-red;
		}
	}
}

.modal__rc--change-email{
	.modal{
		&-dialog{
			@include break-max($mob){
				margin: 0;
				max-width: 100%;
			}
		}
		&-content{
			padding: 0 20px 20px 20px;
			@include break-min($mob){
				padding: 5px 30px 25px 30px;
			}
		}
		&-body,
		&-header,
		&-footer{
			padding: 0;
			margin: 0;
		}

		&-header{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;
			min-height: 50px;
			padding: 12px 0;
			border-bottom: 2px solid $color-marketing;
			margin-bottom: 20px;

			@include break-max($mob){
				padding: 0;
				margin: 0;
				border: none;
			}

			.modal-title{
				font-weight: bolder;
				font-size: 18px;
				line-height: 1.2;
			}
		}

		&-header__close{
			height: 40px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $border-color;
			@include break-min($mob){
				display: none;
			}
			.btn-secondary-outline{
				background: transparent;
				padding: 0;
				margin: 0;
				justify-content: flex-start;
				border: none;
				height: 36px;
				display: inline-flex;
				align-items: center;
				gap: 10px;
				svg{
					height: 20px;

					path{
						fill: $color-neutral-dark;
					}
				}
			}
		}

		&-body{
			margin-bottom: 20px;
		}

		&-footer{
			display: block;
			border-top: none;

			.close{
				@include break-max($mob){
					display: none;
				}
			}

			.btn-bar--row{
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
				flex-wrap: wrap;
				@include break-min($mob){
					justify-content: flex-end;
				}
			}
		}
	}
}

.rc--check-email--loading-next-step{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color-neutral-dark, 0.5);
	transition: all 0.3s ease-out;
	scale: 0.88;
	opacity: 0;
	visibility: hidden;
	z-index: 9999;

	.loading-next-step--content{
		svg{
			height: 250px;
		}
	}
}

.go-to-next-step{
	.rc--check-email--loading-next-step{
		display: flex;
		scale: 1;
		opacity: 1;
		visibility: visible;

		.loading-next-step--content{
			svg{
				animation: bounce infinite 2s ease;
			}
		}
	}
}

@keyframes bounce {
	from,
	20%,
	53%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -15px, 0);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -4px, 0);
		transform: translate3d(0, -4px, 0);
	}
}

.rc--alert{
	display: none;
	margin-bottom: 20px;
	border-radius: 6px;
	padding: 12px;
	&.in{
		display: block;
	}

	&-success{
		background: rgba($color-financial, 0.1);
	}
	&-warning{
		background: rgba($color-danger, 0.1);
	}

	&-content{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;

		.p{
			margin: 0;
			padding: 0;
			color: $color-neutral;
		}

		.icon{
			display: inline-block;
			line-height: 1;
			svg{
				height: 16px;
				path{
					fill: $color-neutral-dark;
				}
			}
		}
	}
}

.main-container__form{
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 25px;
	min-height: 99vh;
	padding: 22px;

	.title{
		text-align: center;
		margin-bottom: 12px;
	}

	.card-form__ga{
		max-width: 480px;

		.p{
			margin-bottom: 5px;
		}
	}

	.main-container__form-footer{
		text-align: center;
	}
}

@keyframes pulse-up {
	0%{
		transform: scale(1);
	}
	100%{
		transform: scale(1.06);
	}
}